import React, { useContext, useEffect } from "react";
import Entry from "./Entry";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import MyDataGrid from "../General/Pagination/MyDataGrid";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Edit from "./Edit";
import Create from "./Create";

export default function DepartmentsList(props) {
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [currentEntry, setCurrentEntry] = React.useState(null);
  const [refresh, setRefresh] = React.useState(0);
  const statusValues = [
    { id: -1, text: "NotFound" },
    { id: 0, text: "NotStarted" },
    { id: 1, text: "Running" },
    { id: 2, text: "Completed" },
    { id: 3, text: "Failed" }
  ];
  const isAdmin =
  homeState.userProfile.role === "Admin" ||
  homeState.userProfile.role === "PowerUser";

  const handleClickCreate = (event) => {
    setOpenCreate(true);
  };

  const handleClickEdit = (event, entry) => {
    setCurrentEntry(entry);
    setOpenEdit(true);
  };

  
  const refreshItems = () => {
    setRefresh(refresh + 1);
  };

  const allowActions = isAdmin;

  const tableOptions = {
    columns: [
      {
        name: "Group Name",
        description: "Group Name",
        sortable: true,
        filter: { enabled: false },
        size: 5
      },
      {
        name: "Description",
        description: "Description",
        sortable: true,
        filter: { enabled: false },
        size: 5
      },
      {
        name: "Group Id",
        description: "Group Id",
        sortable: true,
        filter: { enabled: false },
        size: 4
      },
      {
        name: "Created By",
        description: "Created By",
        sortable: true,
        filter: { enabled: false },
        size: 3
      },
      {
        name: "Created At",
        description: "Created At",
        sortable: true,
        filter: { enabled: false },
        size: 2
      },
      {
        name: "Actions",
        description: "",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      }
    ],
    defaultSort: [{ name: "Group Name", order: "-" }],
    additionalFilters: []
  };

  useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "AI Engine Group"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
      {allowActions && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickCreate}
              startIcon={<AddBoxIcon />}
            >
              New AI Engine Group
            </Button>
          </Grid>
        )}
        <Grid item sm={12}>
          <MyDataGrid
            options={tableOptions}
            url={"/api/ai-prompts/EngineGroupList"}
            refresh={refresh}
            label={"AI Engine Groups"}
            renderItem={(item) => <Entry onEdit={handleClickEdit}
            allowActions={allowActions} key={item.id} entry={item} />}
          />
        </Grid>
      </Grid>
      {allowActions && (
        <React.Fragment>
          <Edit
            open={openEdit}
            setOpen={setOpenEdit}
            entry={currentEntry}
            onEdit={refreshItems}
          />
          <Create
            open={openCreate}
            setOpen={setOpenCreate}
            onCreate={refreshItems}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
