import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { HomeAppContext } from "../Home";
import Slide from "@mui/material/Slide";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import Form, { DatasetFormMode } from "./Form";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialEntry = {
  name: "",
  description: "",
  variables: [],
  values: [],
  prompts: [],
  fileIds: []
};

export default function Create({ open, setOpen, onCreate }) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const [internalEntry, setInternalEntry] = useState(initialEntry);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [variablesFromApi, setVariablesFromApi] = useState([]);

  React.useEffect(() => {
    if (open) {
      setInternalEntry(initialEntry);
      setSubmitted(false);
      setErrors([]);
      setLoading(false);
      //getVariables();
    } else {
      setVariablesFromApi([]);
      setInternalEntry(initialEntry);
    }
  }, [open]);

  // const getVariables = () => {
  //   homeDispatch({
  //     type: "NOTIFICATION",
  //     data: { message: "Fetching dataset variables", loading: true }
  //   });
  //   api
  //     .get("/api/datasets/variables/dropdown")
  //     .then((r) => {
  //       homeDispatch({
  //         type: "NOTIFICATION",
  //         data: {
  //           message: "Dataset variables fetched successfuly",
  //           type: "success"
  //         }
  //       });
  //       setVariablesFromApi(r.data);
  //     })
  //     .catch((error) => errorManagement.formErrors(error, homeDispatch));
  // };

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  };

  const handleCloseSave = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Saving dataset", loading: true }
    });
    setSubmitted(true);
    setLoading(true);
    const newDataset = {
      name: internalEntry.name,
      description: internalEntry.description,
      fileIds: internalEntry.fileIds.map((e) => e.id),
      variables: internalEntry.variables,
      values: internalEntry.values
      //prompts: internalEntry.prompts
    };
    api
      .post(`/api/datasets`, newDataset)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: 'Saved dataset "' + response.data.name + '"',
            type: "success"
          }
        });
        setOpen(false);
        setLoading(false);
        onCreate();
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  return internalEntry === null ? null : (
    <Dialog
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-create-entry"
    >
      <DialogTitle id="alert-dialog-create-entry">{"New Dataset"}</DialogTitle>
      <DialogContent>
        <Form
          mode={DatasetFormMode.NEW}
          entity={internalEntry}
          open={open}
          variables={[]}
          errors={errors}
          setEntity={setInternalEntry}
          loading={loading}
          submitted={submitted}
          setSaveDisabled={setSaveDisabled}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCloseSave}
          color="primary"
          variant="outlined"
          autoFocus
          //disabled={loading || saveDisabled}
        >
          Save Dataset
        </Button>
      </DialogActions>
    </Dialog>
  );
}
