import { CircularProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useState } from "react";

import CustomAvatar from "../../../General/CustomAvatar";
import ExecutedStatus from "./ExecutedStatus";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../../../Home";
import api from "../../../../Services/api";
import { errorManagement } from "../../../../Services/errorManagement";
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 20
  },
  Details: {
    paddingLeft: 10,
    borderLeftColor: theme.palette.secondary.main,
    borderLeftStyle: "solid",
    borderLeftWidth: 4
  },
  placeholder: {
    color: theme.palette.text.disabled
  },
  divider: {
    paddingTop: "0!important",
    paddingBottom: "0!important"
  },
  header: {
    fontWeight: "bold"
  },
  MultiChip: {
    marginBottom: "3px",
    marginTop: "4px",
    marginRight: "10px"
  },
  MultiDecorationPrependChip: {
    borderTopLeftRadius: "15px",
    borderBottomLeftRadius: "20px",
    marginTop: "0px",
    marginBottom: "-2px",
    marginLeft: "-8px",
    marginRight: "7px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  iconAlignMiddle: {
    marginRight: 5,
    verticalAlign: "middle"
  },
  simpleIcon: { verticalAlign: "sub", fontSize: 18, marginRight: 5 },
  allIcon: { verticalAlign: "sub", fontSize: 18 },
  HeaderIcon: { verticalAlign: "sub", marginRight: 5 },
  alignRight: { textAlign: "right" },
  htmlTemplate: {
    overflow: "auto",
    "& .myTable": {
      color: theme.palette.mode === "dark" ? "black" : "inherit"
    }
  }
}));

const RenderField = ({ label, value }) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sm={4}>
        <b>{label}</b>
      </Grid>
      <Grid item sm={8}>
        {value}
      </Grid>
    </Grid>
  );
};

const RenderUser = React.memo(({ user }) => {
  return (
    <Grid item>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <CustomAvatar
            text={user.fullName}
            picture={user.picture}
            size={"small"}
          />
        </Grid>
        <Grid item>
          <Typography variant="caption" display="inline">
            {user.fullName}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
});

const RenderDestination = ({ label, value }) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sm={1}>
        <b>{label}</b>
      </Grid>
      <Grid item sm={11}>
        <Grid container spacing={2} alignItems="center">
          {value.map((address, index) => (
            <RenderUser key={index} user={address} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const RenderReplyTo = ({ value }) => {
  const classes = useStyles();

  const hasValue = value !== "" && value !== null;
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sm={1}>
        <b>Reply to</b>
      </Grid>
      <Grid item sm={11}>
        <Typography className={hasValue ? null : classes.placeholder}>
          {hasValue ? value : "noreply@vengaglobal.com"}
        </Typography>
      </Grid>
    </Grid>
  );
};

const RenderDestinations = ({ entry }) => {
  return (
    <Grid container spacing={2} alignItems="center">
      {entry.toUsers.length !== 0 && (
        <Grid item sm={12}>
          <RenderDestination label="To" value={entry.toUsers} Users />
        </Grid>
      )}
      {entry.bccUsers.length !== 0 && (
        <Grid item sm={12}>
          <RenderDestination label="Bcc" value={entry.bccUsers} Users />
        </Grid>
      )}
      {entry.ccUsers.length !== 0 && (
        <Grid item sm={12}>
          <RenderDestination label="Cc" value={entry.ccUsers} />
        </Grid>
      )}
      {entry.replyToUsers.length !== 0 && (
        <Grid item sm={12}>
          <RenderDestination label="Reply to" value={entry.replyToUsers} />
        </Grid>
      )}

      <Grid item sm={12}>
        <RenderReplyTo value={entry.replyToEmail} />
      </Grid>
    </Grid>
  );
};

const RenderFields = ({ entry }) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sm={12}>
        <RenderField label="Subject" value={entry.subject} />
      </Grid>
      <Grid item sm={12}>
        <RenderField
          label="Embed images"
          value={
            entry.embedImages.length !== 0 ? entry.embedImages.join() : "none"
          }
        />
      </Grid>
      <Grid item sm={12}>
        <RenderField
          label="Attachments"
          value={
            entry.attachments.length !== 0 ? entry.attachments.join() : "none"
          }
        />
      </Grid>
      <Grid item sm={12}>
        <RenderField
          label="Created at"
          value={moment.utc(entry.createdAt).local().format("lll")}
        />
      </Grid>
      <Grid item sm={12}>
        <RenderField
          label="Status"
          value={<ExecutedStatus value={entry.executedAt !== null} />}
        />
      </Grid>
    </Grid>
  );
};

const EntryDetails = ({ entry }) => {
  const classes = useStyles();
  const { homeDispatch } = useContext(HomeAppContext);
  const [entryData, setEntryData] = useState(null);

  React.useEffect(() => {
    if (entry !== null)
      api
        .get(`/api/system/ad-hoc-notifications/${entry.id}`)
        .then((r) => {
          setEntryData(r.data);
        })
        .catch((error) => {
          errorManagement.formErrors(error, homeDispatch);
        });
  }, [entry, homeDispatch]);

  return entryData !== null ? (
    <Grid container spacing={4} className={classes.Details}>
      <Grid item sm={12}>
        <RenderDestinations entry={entryData} />
      </Grid>
      <Grid item sm={5}>
        <RenderFields entry={entryData} />
      </Grid>
      <Grid item sm={7}>
        <div
          className={classes.htmlTemplate}
          dangerouslySetInnerHTML={{ __html: entryData.template }}
        />
      </Grid>
    </Grid>
  ) : (
    <CircularProgress />
  );
};

export default EntryDetails;
