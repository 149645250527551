import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { HomeAppContext } from "../Home";
import Slide from "@mui/material/Slide";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import Form, { AIPromptFormMode } from "./Form";
import { set } from "lodash";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialEntry = {
  promptName: "",
  aiEngines: [],
  prompt: "",
  description: "",
  files: [],
  tags: [],
  dataset: "",
  engineFamily: {
    id: "1",
    text: "OpenAI"
  },
  temperature: 0.0,
  isJson: false
};

export default function Create({ open, setOpen, onCreate }) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const [internalEntry, setInternalEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [promptNeedsReviewed, setPromptNeedsReviewed] = useState(false);
  const [tagsFromApi, setTagsFromApi] = useState([]);
  const [familiesFromApi, setFamiliesFromApi] = useState([]);

  React.useEffect(() => {
    if (open) {
      setSubmitted(false);
      setErrors([]);
      setInternalEntry(initialEntry);
      setLoading(false);
      //setPromptNeedsReviewed(true);
      getTags();
      getEngineFamilies();
    }
  }, [open]);

  const getTags = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Fetching prompt tags", loading: true }
    });
    api
      .get("/api/ai-prompts/tags/dropdown")
      .then((r) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "Prompt tags fetched successfuly", type: "success" }
        });
        setTagsFromApi(r.data);
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
    setInternalEntry(initialEntry);
  };

  const handleReviewPrompt = () => {
    const reviewDto = {
      promptName: internalEntry.promptName,
      aiEngines: internalEntry.aiEngines.map((e) => e.id),
      prompt: internalEntry.prompt,
      description: internalEntry.description,
      tags: internalEntry.tags,
      temperature: internalEntry.temperature,
      isJson: internalEntry.isJson
    };
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Reviewing AI prompt", loading: true }
    });
    api
      .post("/api/ai-prompts/review-prompt", reviewDto)
      .then((r) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: "Reviewing AI prompt success",
            type: "success"
          }
        });
        setPromptNeedsReviewed(false);
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  };

  const handleCloseSave = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Saving AI Prompt", loading: true }
    });
    setSubmitted(true);
    setLoading(true);
    const newPrompt = {
      promptName: internalEntry.promptName,
      aiEngines: internalEntry.aiEngines.map((e) => e.id),
      prompt: internalEntry.prompt,
      description: internalEntry.description,
      //files: internalEntry.files.map((e) => e.id),
      tags: internalEntry.tags,
      dataset: internalEntry.dataset,
      temperature: internalEntry.temperature,
      isJson: internalEntry.isJson
    };
    api
      .post(`/api/ai-prompts`, newPrompt)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: 'Saved AI Prompt "' + response.data.promptName + '"',
            type: "success"
          }
        });
        setOpen(false);
        setLoading(false);
        setInternalEntry(initialEntry);
        onCreate();
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  const getEngineFamilies = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Fetching engine families", loading: true }
    });
    api
      .get("/api/ai-engines/family/dropdown")
      .then((r) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: "Engine families fetched successfuly",
            type: "success"
          }
        });
        setFamiliesFromApi(r.data);
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  };

  return internalEntry === null ? null : (
    <Dialog
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-create-entry"
    >
      <DialogTitle id="alert-dialog-create-entry">
        {"New AI Prompt"}
      </DialogTitle>
      <DialogContent>
        <Form
          mode={AIPromptFormMode.NEW}
          entity={internalEntry}
          tags={tagsFromApi}
          engineFamilies={familiesFromApi}
          errors={errors}
          setEntity={setInternalEntry}
          loading={loading}
          submitted={submitted}
          setSaveDisabled={setSaveDisabled}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        {promptNeedsReviewed === true ? (
          <Button
            onClick={handleReviewPrompt}
            variant="outlined"
            color="primary"
          >
            Review Prompt
          </Button>
        ) : (
          <Button
            onClick={handleCloseSave}
            color="primary"
            variant="outlined"
            autoFocus
            disabled={loading || saveDisabled}
          >
            Save Prompt
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
