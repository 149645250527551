import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { HomeAppContext } from "../Home";
import Slide from "@mui/material/Slide";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import Form, { AIEngineFormMode } from "./Form";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialEntry = {
  engineName: "",
  apiUrl: "",
  apiToken: "",
  description: "",
  isAvailableToTeam: false,
  apiCost: 0,
  engineFamily: "",
  temperature: 0.0
};

export default function Create({ open, setOpen, onCreate }) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const [internalEntry, setInternalEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [connectionNeedsTested, setConnectionNeedsTested] = useState(true);
  const [familiesFromApi, setFamiliesFromApi] = useState([]);

  React.useEffect(() => {
    if (open) {
      setSubmitted(false);
      setErrors([]);
      setInternalEntry(initialEntry);
      setLoading(false);
      setConnectionNeedsTested(true);
    } else {
      setInternalEntry(null);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  };

  const handleTestConnection = () => {
    const testDto = {
      name: internalEntry.groupName,
      description: internalEntry.description,
    };
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Testing AI Engine connection", loading: true }
    });
    api
      .post("/api/ai-prompts/AddEngineGroup", testDto)
      .then((r) => {
        if (r.data) {
          homeDispatch({
            type: "NOTIFICATION",
            data: {
              message: "Testing AI Engine connection success",
              type: "success"
            }
          });
          setOpen(false);
          setLoading(false);
          onCreate();        
        }
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  const handleCloseSave = () => {
    console.log("handleCloseSave");
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Saving AI Engine", loading: true }
    });
    setSubmitted(true);
    setLoading(true);
    const newEngine = {
      engineName: internalEntry.engineName,
      apiUrl: internalEntry.apiUrl,
      apiToken: internalEntry.apiToken,
      description: internalEntry.description,
      isAvailableToTeam: internalEntry.isAvailableToTeam,
      apiCost: internalEntry.apiCost,
      engineFamily: internalEntry.engineFamily,
      temperature: internalEntry.temperature
    };
    api
      .post(`/api/ai-engines`, newEngine)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: "Saved AI Engine " + response.data.engineName,
            type: "success"
          }
        });
        setOpen(false);
        setLoading(false);
        onCreate();
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  const isFormValid = () => {
    return internalEntry && internalEntry.groupName;
  };

  return internalEntry === null ? null : (
    <Dialog
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-create-entry"
    >
      <DialogTitle id="alert-dialog-create-entry">
        {"New AI Engine Group"}
      </DialogTitle>
      <DialogContent>
        <Form
          mode={AIEngineFormMode.NEW}
          entity={internalEntry}
          errors={errors}
          setEntity={setInternalEntry}
          loading={loading}
          submitted={submitted}
          setSaveDisabled={setSaveDisabled}
          engineFamilies={familiesFromApi}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        {connectionNeedsTested === true ? (
          <Button
            onClick={handleTestConnection}
            variant="outlined"
            color="primary"
            disabled={!isFormValid()}
          >
            Create
          </Button>
        ) : (
          <Button
            onClick={handleCloseSave}
            color="primary"
            variant="outlined"
            autoFocus
            disabled={loading || saveDisabled || !isFormValid()}
          >
            Save Engine
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
