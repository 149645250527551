import { Hidden, useMediaQuery } from "@mui/material";
import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel
} from "@microsoft/signalr";
import React, { useContext, useEffect, useReducer } from "react";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
  useTheme
} from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Notification from "./Notification";
import RenderRouter from "./RenderRouter";
import SidePanel from "./General/SidePanel";
import Sidebar from "./Sidebar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import UserMenu from "./User/UserMenu";
import api from "../Services/api";
import clsx from "clsx";
import uniqid from "uniqid";
import { useLocation } from "react-router-dom";
import { userStore } from "../Services/store";

const drawerWidthOpen = 240;
const drawerWidthClosed = 65;

const lightTheme = createTheme({
  palette: {
    mode: "light",
    secondary: {
      main: "#2F4050",
      light: "#4d6882"
    },
    primary: {
      main: "#0da67a",
      light: "#4ca48b"
    }
  },
  components: {
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent"
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          "&+.MuiDialogContent-root": {
            paddingTop: "20px !important"
          }
        }
      },
      defaultProps: {
        backgroundColor: "#0da67a",
        color: "white"
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: "1.43",
          letterSpacing: "0.01071em"
        }
      }
    }
  }
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#333"
    },
    secondary: {
      main: "#85AFD7",
      light: "#a5bcd1"
    },
    primary: {
      main: "#0da67a",
      light: "#4ca48b"
    }
  },
  components: {
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent"
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          "&+.MuiDialogContent-root": {
            paddingTop: "20px !important"
          }
        }
      },
      defaultProps: {
        backgroundColor: "#0da67a",
        color: "white"
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: "1.43",
          letterSpacing: "0.01071em"
        }
      }
    }
  }
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  appBarColors: { backgroundColor: "#0da67a", color: "white" },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  drawerOpen: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidthOpen,
      flexShrink: 0
    }
  },
  drawerClosed: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidthClosed,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidthOpen}px)`,
      marginLeft: drawerWidthOpen
    }
  },
  appBarMenuClosed: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidthClosed}px)`,
      marginLeft: drawerWidthClosed
    }
  },
  appBarFull: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidthOpen
    }
  },
  menuButtonMobile: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  drawerPaperOpen: {
    width: drawerWidthOpen
  },
  drawerPaperClosed: {
    width: drawerWidthClosed
  },
  logo: {
    width: 20,
    height: 20,
    marginRight: 10,
    marginBottom: 5
  },
  title: {
    flexGrow: 1
  },
  content: {
    background: theme.palette.background.default,
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    height: "100%"
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  }
}));

export const HomeAppContext = React.createContext();

const initialState = {
  connection: null,
  currentLocation: "",
  notification: "",
  userProfile: userStore.user_profile,
  sidePanelInfo: []
};

function reducer(state, action) {
  switch (action.type) {
    case "UPDATE_CURRENT_LOCATION":
      return {
        ...state,
        currentLocation: "AI Prompt Manager - " + action.data
      };
    case "NOTIFICATION":
      return { ...state, notification: { ...action.data, key: uniqid() } };
    case "NOTIFICATION_REPORT":
      if (state.connection.state === HubConnectionState.Disconnected)
        state.connection.start();
      return { ...state, notification: { ...action.data, key: uniqid() } };
    case "ADD_TO_SIDE_PANEL_INFO":
      const previousTask = state.sidePanelInfo.find(
        (task) => task.guid === action.data.guid
      );
      if (previousTask !== undefined) {
        return {
          ...state,
          sidePanelInfo: state.sidePanelInfo.map((task) =>
            task.guid === action.data.guid
              ? { ...task, messages: task.messages.concat([action.data]) }
              : task
          )
        };
      } else {
        return {
          ...state,
          sidePanelInfo: state.sidePanelInfo.concat([
            {
              guid: action.data.guid,
              variation: action.data.variation,
              messages: [action.data]
            }
          ])
        };
      }
    case "UPDATE_USER_PROFILE":
      if (
        state.userProfile === null ||
        state.userProfile.userId !== action.data.userId
      ) {
        return { ...state, userProfile: { ...action.data, key: uniqid() } };
      } else {
        return state;
      }
    case "UPDATE_USER_PROFILE_VALUES":
      userStore.user_profile = action.data;

      return {
        ...state,
        userProfile: { ...state.userProfile, ...action.data, key: uniqid() }
      };

    case "CLEAR_USER_PROFILE":
      if (state.connection) {
        state.connection.stop();
      }
      return { ...state, sidePanelInfo: [], userProfile: null };
    case "SET_CONNECTION":
      return { ...state, connection: action.data };
    default:
      return initialState;
  }
}

const Main = (props) => {
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(userStore.sidebarMenuOpen);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const handleMenuToggle = () => {
    const value = !menuOpen;
    setMenuOpen(value);
    userStore.sidebarMenuOpen = value;
  };

  useEffect(() => {
    if (userStore.user_profile !== null) {
      homeDispatch({
        type: "UPDATE_USER_PROFILE",
        data: userStore.user_profile
      });
    } else {
      homeDispatch({
        type: "CLEAR_USER_PROFILE",
        data: null
      });
    }
  }, []);

  useEffect(() => {
    if (homeState.userProfile !== null) {
      if (homeState.connection) {
        homeState.connection.stop();
      }
      const newConnection = new HubConnectionBuilder()
        .configureLogging(LogLevel.None)
        .withUrl(process.env.REACT_APP_BACKEND_URL + "/notificationhub", {
          accessTokenFactory: async () => {
            let accessToken = null;
            await api
              .get("/api/login/authorized")
              .then(async (response) => {
                accessToken = userStore.token;
              })
              .catch((error) => {
                console.log(error);
              });

            return accessToken;
          }
        })
        .withAutomaticReconnect()
        .build();
      homeDispatch({
        type: "SET_CONNECTION",
        data: newConnection
      });
    } else {
      if (homeState.connection) {
        homeState.connection.stop();
      }
      const newConnection = new HubConnectionBuilder()
        .configureLogging(LogLevel.None)
        .withUrl(process.env.REACT_APP_BACKEND_URL + "/notificationhub")
        .withAutomaticReconnect()
        .build();
      homeDispatch({
        type: "SET_CONNECTION",
        data: newConnection
      });
    }
  }, [homeState.userProfile]);

  useEffect(() => {
    if (
      homeState.connection &&
      homeState.connection.state === HubConnectionState.Disconnected
    ) {
      homeState.connection
        .start()
        .then((result) => {
          homeState.connection.on("ReceiveMessage", (notification) => {
            homeDispatch({
              type: "NOTIFICATION",
              data: { message: notification.message, type: notification.type }
            });
          });
          homeState.connection.on(
            "ReceiveTaskProgressMessage",
            (notification) => {
              homeDispatch({
                type: "ADD_TO_SIDE_PANEL_INFO",
                data: notification
              });
            }
          );
          homeState.connection.on("ReceiveDownloadLink", (notification) => {
            window.location = notification.link;
          });
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [homeState.connection]);

  const getMaxWidth = () => {
    switch (location.pathname) {
      case "/ai-engines":
      case "/ai-prompts":
      case "/ai-assistants":
      case "/data-sets":
        return false;
      default:
        return "lg";
    }
  };

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        className={clsx(
          "nonPrintArea",
          classes.appBarColors,
          homeState.userProfile !== null
            ? menuOpen
              ? classes.appBar
              : classes.appBarMenuClosed
            : classes.appBarFull
        )}
      >
        <Toolbar className={classes.toolbar}>
          {homeState.userProfile !== null ? (
            <React.Fragment>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButtonMobile}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleMenuToggle}
                className={classes.menuButton}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </React.Fragment>
          ) : null}
          <img
            src="/favicon-196x196-white.png"
            alt="logo"
            className={classes.logo}
          />
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {homeState.currentLocation}
          </Typography>
          {homeState.userProfile !== null ? (
            <UserMenu userProfile={homeState.userProfile} />
          ) : null}
        </Toolbar>
      </AppBar>
      {homeState.userProfile !== null ? (
        <nav
          className={clsx(
            "nonPrintArea",
            menuOpen ? classes.drawerOpen : classes.drawerClosed
          )}
          aria-label="sidebar-menu"
        >
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaperOpen
              }}
              ModalProps={{
                keepMounted: true
              }}
            >
              <div className={classes.toolbarIcon} />
              <Divider />
              <Sidebar
                userProfile={homeState.userProfile}
                handleDrawerClose={handleDrawerClose}
              />
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: menuOpen
                  ? classes.drawerPaperOpen
                  : classes.drawerPaperClosed
              }}
              variant="permanent"
              open
            >
              <div className={classes.toolbarIcon} />
              <Divider />
              <Sidebar
                userProfile={homeState.userProfile}
                handleDrawerClose={handleDrawerClose}
                menuOpen={menuOpen}
              />
            </Drawer>
          </Hidden>
        </nav>
      ) : null}
      <main className={classes.content} id="appMainContent">
        <div className={clsx("nonPrintArea", classes.appBarSpacer)} />
        <Container
          maxWidth={getMaxWidth()}
          className={clsx("printArea", classes.container)}
        >
          <RenderRouter userProfile={homeState.userProfile} />
        </Container>
        {homeState.notification ? (
          <Notification
            key={homeState.notification.key}
            value={homeState.notification}
          />
        ) : null}
        <SidePanel sidePanelInfo={homeState.sidePanelInfo} />
      </main>
    </React.Fragment>
  );
};

function Home(props) {
  const [homeState, homeDispatch] = useReducer(reducer, initialState);

  const autoDark = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersDarkMode = homeState.userProfile
    ? homeState.userProfile.theme === "Auto"
      ? autoDark
      : homeState.userProfile.theme === "Dark"
    : autoDark;

  return (
    <HomeAppContext.Provider value={{ homeState, homeDispatch }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={prefersDarkMode ? darkTheme : lightTheme}>
          <CssBaseline />
          <Main />
        </ThemeProvider>
      </StyledEngineProvider>
    </HomeAppContext.Provider>
  );
}

export default Home;
