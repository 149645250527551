import { useState, useContext } from "react";
import {
  CircularProgress,
  TableCell,
  TableRow,
  Typography,
  Collapse,
  Box,
  Chip
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import React from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {
  GeDeleteIcon,
  GeEnableIcon,
  GeSaveIcon
} from "../General/GeneralIcons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { dayjs } from "../../Services/dateUtil";
import TabularField from "../General/TabularField";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import MyDataGrid from "../General/Pagination/MyDataGrid";

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 20
  }
}));

const formatText = (string) =>
  string
    ? string.split("\\n").map((item, key) => {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        );
      })
    : "";
const parProperties = (string) => {
  try {
    const json = JSON.parse(string);
    return formatText(JSON.stringify(json, null, "\t"));
  } catch (e) {
    return string;
  }
};

const Entry = ({
  entry,
  allowActions = true,
  isAdmin = false,
  onEdit,
  onRemove
}) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const showActions =
    allowActions && (isAdmin ? true : entry.role.name !== "Admin");

  const handleClickEdit = (event) => {
    onEdit(event, entry);
  };

  const handleClickRemove = (event) => {
    onRemove(event, entry);
  };

  const toggle = () => {
    setShow((show) => !show);
  };

  const replaceCommasWithTabs = (str) => {
    return str.replace(/,/g, "\t");
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "&>td": { borderBottomStyle: "none" } }}>
        <TableCell>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton onClick={toggle} size="small">
                {show ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Typography variant="body2" display="inline">
                {entry.name}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.createdBy.fullName}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {dayjs(entry.createdAt)
              .utc("z")
              .local()
              .format("YYYY-MM-DD, HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          <Grid container spacing={1}>
            {entry.variables.map((variable, index) => (
              <Grid item key={index}>
                <Chip label={variable} size="small" />
              </Grid>
            ))}
          </Grid>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          {showActions && (
            <React.Fragment>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="edit dataset"
                component="span"
                onClick={handleClickEdit}
                title="Edit dataset"
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="remove dataset"
                component="span"
                onClick={handleClickRemove}
                title="Remove dataset"
              >
                <GeDeleteIcon fontSize="inherit" />
              </IconButton>
            </React.Fragment>
          )}
        </TableCell>
      </TableRow>
      <TableRow sx={{ "&>td": { borderBottomStyle: "none" } }}>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={show} mountOnEnter unmountOnExit>
            <Box className={classes.detailsContainer}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <TabularField
                    label={"Description"}
                    labelGridSize={3}
                    value={
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {parProperties(entry.description)}
                      </div>
                    }
                  />
                </Grid>
                <Grid item sm={6}></Grid>
                {entry.prompts?.length > 0 && (
                  <React.Fragment>
                    <Grid item sm={6}>
                      <TabularField
                        label={"Prompts"}
                        labelGridSize={3}
                        value={entry.prompts
                          .map((entry, index) => entry.text)
                          .join(", ")}
                      />
                    </Grid>
                    <Grid item sm={6}></Grid>
                  </React.Fragment>
                )}
                {entry.files?.length > 0 && (
                  <React.Fragment>
                    <Grid item sm={6}>
                      <TabularField
                        label={"Dataset File"}
                        labelGridSize={3}
                        value={entry.files.join(", ")}
                      />
                    </Grid>
                    <Grid item sm={6}></Grid>
                  </React.Fragment>
                )}
                <Grid item sm={6}>
                  <TabularField
                    label={"Content"}
                    labelGridSize={3}
                    value={
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {parProperties(entry.variables.join("\t"))}
                        {parProperties("\n")}
                        {parProperties(
                          entry.values.map((entry, index) => (
                            <div key={index}>
                              {replaceCommasWithTabs(entry)}
                            </div>
                          ))
                        )}
                      </div>
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Entry;
