import React, { useContext, useEffect, useState } from "react";

import Entry from "./Entry";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import MyDataGrid from "../General/Pagination/MyDataGrid";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import dayjs from "dayjs";
import TimerProgress from "./TimedProgress";

const levels = [
  { id: "Verbose", text: "Verbose" },
  { id: "Debug", text: "Debug" },
  { id: "Information", text: "Information" },
  { id: "Warning", text: "Warning" },
  { id: "Fatal", text: "Fatal" },
  { id: "Error", text: "Error" }
];

export const periodLogs = [
  {
    label: "Current month",
    from: dayjs().startOf("month").startOf("day"),
    to: dayjs().endOf("month").endOf("day")
  },
  {
    label: "Last month",
    from: dayjs().subtract(1, "month").startOf("month").startOf("day"),
    to: dayjs().subtract(1, "month").endOf("month").endOf("day")
  }
];

const initialState = {
  refreshEvery: 6000,
  refresh: 0,
  autoRefresh: false
};
export default function List(props) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("trying refreshing");
      if (state.autoRefresh) {
        console.log("refreshing");
        setState((prevState) => ({
          ...prevState,
          refresh: prevState.refresh + 1
        }));
      }
    }, state.refreshEvery);

    return () => clearInterval(interval);
  }, [state.autoRefresh, state.refreshEvery]);

  const onAutoRefreshToggle = () => {
    setState((prevState) => ({
      ...prevState,
      autoRefresh: !prevState.autoRefresh
    }));
  };

  const tableOptions = {
    columns: [
      {
        name: "Timestamp",
        description: "Timestamp",
        sortable: true,
        filter: {
          enabled: false
        },
        size: "210px"
      },
      {
        name: "Level",
        description: "Level",
        sortable: true,
        align: "center",
        filter: {
          enabled: false
        },
        size: "100px"
      },
      {
        name: "User.FullName",
        description: "User",
        sortable: true,
        filter: {
          enabled: false
        },
        size: "50px"
      },
      {
        name: "Message",
        description: "Message",
        sortable: false,
        filter: {
          enabled: false
        },
        size: "800px"
      }
    ],
    defaultSort: [{ name: "Timestamp", order: "-" }],
    additionalFilters: [
      {
        name: "Period",
        description: "Period",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "dateRange",
          defaultValue: 0,
          value: periodLogs,
          options: {
            addNullPeriod: true
          }
        },
        size: 3
      },
      {
        name: "Level",
        description: "Level",
        filter: {
          enabled: true,
          comparison: "==",
          type: "select",
          value: levels,
          defaultValue: ""
        },
        size: 2
      },
      {
        name: "Message",
        description: "Message",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Message text"
        },
        size: 4
      },
      {
        name: "IsUser",
        description: "User",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: `api/users/dropdown`,
            multiple: false,
            liveOptions: true,
            checkbox: false,
            forceAvatar: true
          }
        },
        size: 3
      },
      {
        name: "SourceContextIsAnyOf",
        description: "Context",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: `api/logs/source-contexts/dropdown`,
            multiple: true,
            liveOptions: true,
            checkbox: true
          }
        },
        size: 3
      },

      {
        name: "Guid",
        description: "Guid",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Guid"
        },
        size: 4
      },
      {
        name: "Properties",
        description: "Properties",
        filter: {
          enabled: true,
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Properties"
        },
        size: 4
      }
    ]
  };

  React.useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "Logs"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                startIcon={
                  state.autoRefresh ? (
                    <TimerProgress time={state.refreshEvery} />
                  ) : (
                    <RefreshIcon />
                  )
                }
                onClick={onAutoRefreshToggle}
                color={state.autoRefresh ? "success" : "secondary"}
                variant={"outlined"}
              >
                Auto refresh every {state.refreshEvery / 1000} seconds
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MyDataGrid
            options={tableOptions}
            url={"/api/logs"}
            label={"log items"}
            renderItem={(item, index) => <Entry key={index} entry={item} />}
            rowsPerPageOptions={[50, 100, 200, 400, 800]}
            rowsPerPage={50}
            refresh={state.refresh}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
