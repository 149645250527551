import { Box, Container, IconButton } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import BackspaceIcon from "@mui/icons-material/Backspace";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../Home";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import makeStyles from "@mui/styles/makeStyles";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { formatBytes } from "../../Services/constantsAndTools";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles((theme, height) => ({
  filesContainer: {
    height: { height },
    padding: 10,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    overflowY: "auto"
  },
  progressBar: {
    height: 2
  }
}));

const DragAndDropContainer = styled(Container)(({ theme, height }) => ({
  borderRadius: 5,
  borderWidth: 2,
  borderStyle: "dashed",
  borderColor: theme.palette.primary.main,
  height: { height },
  minWidth: 50,
  padding: "20px 20px",
  textAlign: "center"
}));

const DragAndDropContainerDisabled = styled(Container)(({ theme, height }) => ({
  borderRadius: 5,
  borderWidth: 2,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
  height: { height },
  minWidth: 50,
  padding: "20px 20px",
  textAlign: "center"
}));

const FileBox = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: "2px 2px 2px 10px",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.secondary.main,
  borderLeftColor: theme.palette.primary.main,
  borderLeftStyle: "solid",
  borderLeftWidth: 10,
  fontSize: 0
}));

const initialState = {
  counter: 0,
  files: []
};

const FileUploader = ({
  uploadUrl = "",
  acceptedFileExtensions = ".*",
  acceptedFileExtensionsMessage = ".*",
  numberOfFilesCanBeUploaded = -1,
  maxSize = 10485760,
  onFileUploaded = null,
  onUploading = null,
  maxFiles = 0,
  height = 100,
  disabled = false
}) => {
  const classes = useStyles();
  const { homeDispatch } = useContext(HomeAppContext);
  const [state, setState] = useState(initialState);
  const isDragEnabled = maxFiles === 0 || state.files.length < maxFiles;

  const onFileUploadCallback = useCallback(
    (files) => {
      onFileUploaded(files);
    },
    [onFileUploaded]
  );

  const onUploadingCallback = useCallback(
    (value) => {
      if (onUploading != null) onUploading(value);
    },
    [onUploading]
  );

  useEffect(() => {
    onFileUploadCallback(state.files);
    onUploadingCallback(state.files.some((x) => x.uploading === true));
  }, [onFileUploadCallback, onUploadingCallback, state.files]);

  const addFile = (uuid, file) => {
    const newFile = {
      uuid: uuid,
      name: file.name,
      id: "",
      progress: 0,
      errorUploading: false,
      uploading: true,
      remove: false,
      error: false
    };
    setState((prevState) => ({
      ...prevState,
      counter: prevState.counter + 1,
      files: [...prevState.files, { ...newFile, number: prevState.counter + 1 }]
    }));
  };

  const setFileProperty = (uuid, properties) => {
    setState((prevState) => ({
      ...prevState,
      files: prevState.files.map((x) =>
        x.uuid === uuid ? { ...x, ...properties } : x
      )
    }));
  };

  const uploadFile = (file) => {
    const uuid = uuidv4();

    addFile(uuid, file);

    const form = new FormData();
    form.append("body", file);

    api
      .post(uploadUrl, form, {
        timeout: 0,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          setFileProperty(uuid, {
            progress: Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
          });
        }
      })
      .then((r) => {
        setFileProperty(uuid, {
          id: r.data.id,
          uploading: false
        });
      })
      .catch((error) => {
        setFileProperty(uuid, { uploading: false, error: true });
        errorManagement.formErrors(error, homeDispatch);
      });
  };

  const markToRemoveFile = (file) => {
    setFileProperty(file.uuid, {
      remove: true
    });
  };

  const removeFile = (file) => {
    deleteFile(file);
  };

  const deleteFile = (file) => {
    api
      .delete(`${uploadUrl}/${file.id}`)
      .then((r) => {
        setState((prevState) => ({
          ...prevState,
          files: prevState.files.filter((x) => x.uuid !== file.uuid)
        }));
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          files: prevState.files.filter((x) => x.uuid !== file.uuid)
        }));
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message:
              "Problem detected while deleting file from server: " + file.name
          }
        });
      });
  };

  const onDrop = (acceptedFiles) => {
    if (maxFiles === 0) {
      acceptedFiles.forEach((file) => {
        uploadFile(file);
      });
    } else {
      const size =
        state.files.length > maxFiles ? 0 : maxFiles - state.files.length;
      acceptedFiles.slice(0, size).forEach((file) => {
        uploadFile(file);
      });
    }
  };

  const { isDragActive, getRootProps, getInputProps, fileRejections } =
    useDropzone({
      accept: acceptedFileExtensions,
      onDrop: (acceptedFiles, rejectedFiles) => onDrop(acceptedFiles),
      minSize: 0,
      maxSize
    });

  useEffect(() => {
    if (fileRejections.length > 0) {
      const notSupported = fileRejections.filter((x) =>
        x.errors.some((y) => y.code === "file-invalid-type")
      );
      if (notSupported.length > 0)
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: `${notSupported.length} ${notSupported.length === 1 ? "file extension" : "files extensions"} not supported`,
            type: "error"
          }
        });
      const tooLarge = fileRejections.filter((x) =>
        x.errors.some((y) => y.code === "file-too-large")
      );
      if (tooLarge.length > 0)
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: `${tooLarge.length} ${tooLarge.length === 1 ? "file" : "files"} exceeded the maximum file size allowed`,
            type: "error"
          }
        });
    }
  }, [fileRejections, homeDispatch]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <div className="container text-center mt-5">
          {isDragEnabled ? (
            <DragAndDropContainer height={height} {...getRootProps()}>
              <input {...getInputProps()} disabled={disabled} />
              <Grid container justifyContent={"center"} alignItems="center">
                <Grid item>
                  <CloudUploadOutlinedIcon style={{ marginRight: 10 }} />
                </Grid>
                <Grid item>
                  {!isDragActive && (
                    <React.Fragment>
                      <Typography>
                        Click here or drop files to upload
                      </Typography>
                      <Typography variant="caption">
                        Acceptable files: {acceptedFileExtensionsMessage}
                      </Typography>
                      {numberOfFilesCanBeUploaded !== -1 ? (
                        <Typography variant="caption" display={"block"}>
                          Number of files can be uploaded:{" "}
                          {numberOfFilesCanBeUploaded}
                        </Typography>
                      ) : null}
                    </React.Fragment>
                  )}
                  {isDragActive && (
                    <Typography>Drop the files to upload it</Typography>
                  )}
                  <Typography variant="caption" display={"block"}>
                    Max file size {formatBytes(maxSize)}
                  </Typography>
                </Grid>
              </Grid>
            </DragAndDropContainer>
          ) : (
            <DragAndDropContainerDisabled height={height}>
              <Grid container justifyContent={"center"} alignItems="center">
                <Grid item>
                  <CloudOffIcon style={{ marginRight: "10px" }} />
                </Grid>
                <Grid item>
                  <Typography>Max file upload reached ({maxFiles})</Typography>
                </Grid>
              </Grid>
            </DragAndDropContainerDisabled>
          )}
        </div>
      </Grid>
      <Grid item sm={12}>
        {state.files.length > 0 && (
          <div className={classes.filesContainer} style={{ height: height }}>
            <Grid container spacing={1}>
              {state.files.map((file) => (
                <Zoom
                  key={file.uuid}
                  in={!file.remove}
                  onExited={() => removeFile(file)}
                >
                  <Grid item sm={6}>
                    <FileBox>
                      <Grid
                        container
                        spacing={2}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        alignContent={"flex-end"}
                      >
                        <Grid item xs={10}>
                          <Typography
                            display="block"
                            variant="caption"
                            title={file.name}
                            noWrap={true}
                          >
                            <b>{file.number}</b> - {file.name}
                            <LinearProgress
                              className={classes.progressBar}
                              variant="determinate"
                              value={file.progress}
                            />
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            title="Remove this this file"
                            aria-label="remove"
                            size="small"
                            onClick={() => markToRemoveFile(file)}
                            disabled={disabled}
                          >
                            <BackspaceIcon fontSize="inherit" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </FileBox>
                  </Grid>
                </Zoom>
              ))}
            </Grid>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default FileUploader;
