import {
  Checkbox,
  FormControlLabel,
  TextField,
  LinearProgress
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import FileUploader from "../General/FileUploader";

export default function AssistantChatForm({
  entity = null,
  errors = [],
  result = null,
  setEntity,
  loading,
  submitted,
  setSaveDisabled
}) {
  const [fileCountRemaining, setFileCountRemaining] = useState(1);

  const onInputChange = (event, property) => {
    setEntity({ ...entity, [property]: event.target.value });
  };

  const onFileUploaded = (value) => {
    const uploadedFiles = value.map((item) => item.id);

    setEntity({
      ...entity,
      fileIds: uploadedFiles
    });

    setFileCountRemaining(1 - uploadedFiles.length);
  };

  return entity === null ? null : (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item sm={12}>
          <Grid item sm={12}>
            <FileUploader
              onFileUploaded={onFileUploaded}
              uploadUrl="/api/files/"
              acceptedFileExtensions=".txt, .pdf, .docx"
              acceptedFileExtensionsMessage={".txt, .pdf, .docx"}
              numberOfFilesCanBeUploaded={fileCountRemaining}
              maxSize={52428800}
              maxFiles={1}
            ></FileUploader>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid item sm={12}>
            <TextField
              label="Text/Prompt"
              fullWidth
              value={entity.prompt}
              multiline
              rows={4}
              onChange={(event) => onInputChange(event, "prompt")}
            />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          {loading ? <LinearProgress /> : null}
        </Grid>
        <Grid item sm={12}>
          {" "}
        </Grid>
        {result && result !== null ? (
          <>
            <Grid item sm={12}>
              <InputLabel>Assistant Response:</InputLabel>
            </Grid>
            <Grid item sm={12}>
              {result}
            </Grid>
          </>
        ) : null}
      </Grid>
    </React.Fragment>
  );
}
