import React, { useContext } from "react";

import AddBoxIcon from "@mui/icons-material/AddBox";
import Button from "@mui/material/Button";
import ConfirmDialog from "../../General/ConfirmDialog";
import Create from "./Create";
import Edit from "./Edit";
import Entry from "./Entry";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../../Home";
import api from "../../../Services/api";
import { errorManagement } from "../../../Services/errorManagement";
import MyDataGrid from "../../General/Pagination/MyDataGrid";
import CreateServiceAccount from "./CreateServiceAccount";

const statusValues = [
  { id: "true", text: "Active" },
  { id: "false", text: "Inactive" }
];

export default function List(props) {
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openServiceAccountCreate, setOpenServiceAccountCreate] = React.useState(false);
  const [openReset, setOpenReset] = React.useState(false);
  const [resetParams, setResetParams] = React.useState(false);
  const [currentEntry, setCurrentEntry] = React.useState(null);
  const [refresh, setRefresh] = React.useState(0);

  const isAdmin =
    homeState.userProfile.role === "Admin" ||
    homeState.userProfile.role === "PowerUser";

  const allowActions = isAdmin;

  const handleClickCreate = (event) => {
    setOpenCreate(true);
  };

  const handleClickServiceAccountCreate = (event) => {
    setOpenServiceAccountCreate(true);
  };

  const tableOptions = {
    columns: [
      {
        name: "FullName",
        description: "Name",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 9
      },
      {
        name: "RoleId",
        description: "Role",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "Actions",
        description: "",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      }
    ],
    defaultSort: [{ name: "FullName", order: "" }],
    additionalFilters: [
      {
        name: "FullName",
        description: "Name",
        filter: {
          enabled: true,
          name: "NameOrEmailLike",
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Filter name/email"
        },
        size: allowActions ? 4 : "auto"
      },
      {
        name: "RoleId",
        description: "Role",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: "/api/roles/dropdown",
            multiple: false,
            liveOptions: false,
            checkbox: false
          }
        },
        size: 2
      },
      {
        name: "Active",
        description: "Active Status",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "select",
          value: statusValues,
          defaultValue: "true"
        },
        size: 2
      }
    ]
  };

  React.useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "Users"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  const handleClickEdit = (event, entry) => {
    setCurrentEntry(entry);
    setOpenEdit(true);
  };

  const handleClickConfirmSendReset = (event, entry) => {
    setResetParams({ id: entry.id });
    setOpenReset(true);
  };
  const cancelSendReset = () => {
    setResetParams(null);
  };

  const sendResetEmail = (params) => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Sending password reset email", loading: true }
    });
    api
      .post(`/api/users/${params.id}/passwordReset`)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: response.data.message, type: response.data.status }
        });
      })
      .catch((error) => {
        errorManagement.formErrors(error, homeDispatch);
      });
  };

  const refreshItems = () => {
    setRefresh(refresh + 1);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"left"}>
        {allowActions && (
          <><Grid item xs={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClickServiceAccountCreate}
            startIcon={<AddBoxIcon />}
          >
            New Service Account User
          </Button>
        </Grid><Grid item xs={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickCreate}
              startIcon={<AddBoxIcon />}
            >
              New User
            </Button>
          </Grid></>
        )}
        <Grid item xs={12}>
          <MyDataGrid
            options={tableOptions}
            url={"/api/users"}
            refresh={refresh}
            label={"Users"}
            renderItem={(item, index) => (
              <Entry
                key={index}
                entry={item}
                allowActions={allowActions}
                isAdmin={isAdmin}
                onEdit={handleClickEdit}
                onSendReset={handleClickConfirmSendReset}
              />
            )}
          />
        </Grid>
      </Grid>
      {allowActions && (
        <React.Fragment>
          <Edit
            open={openEdit}
            setOpen={setOpenEdit}
            entry={currentEntry}
            onEdit={refreshItems}
          />
          <Create
            open={openCreate}
            setOpen={setOpenCreate}
            onCreate={refreshItems}
          />
          <CreateServiceAccount
          open={openServiceAccountCreate}
          setOpen={setOpenServiceAccountCreate}
          onCreate={refreshItems}
        />
          <ConfirmDialog
            open={openReset}
            setOpen={setOpenReset}
            title={"Send reset email"}
            message={
              "Are you sure that you want to send the user an email with instructions to reset their own password?"
            }
            onCloseSuccess={sendResetEmail}
            successParams={resetParams}
            onClose={cancelSendReset}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
