import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import history from "./history";
import { userStore } from "./store";

const api = axios.create({
  timeout: 3600000,
  headers: { "Content-Type": "application/json" },
  baseURL: process.env.REACT_APP_BACKEND_URL
});

function getAccessToken() {
  return userStore.token;
}

function getRefreshToken() {
  return userStore.refresh_token;
}

api.interceptors.request.use((request) => {
  request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
  request.paramsSerializer = {
    indexes: true,
    encode: (str) => {
      return str;
    }
  };
  return request;
});

const refreshAuthLogic = (failedRequest) =>
  axios
    .post(process.env.REACT_APP_BACKEND_URL + "/api/login/refresh", {
      Token: getAccessToken(),
      RefreshToken: getRefreshToken(),
      pauseInstanceWhileRefreshing: true //this parameter is for the internal refresh logic inside this react dependency
    })
    .then((tokenRefreshResponse) => {
      userStore.token = tokenRefreshResponse.data.token;
      userStore.refresh_token = tokenRefreshResponse.data.refreshToken;
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.token;
      return Promise.resolve();
    })
    .catch((error) => {
      //this requires history v 4.10.1
      history.push("/logout");
      return Promise.reject();
    });

createAuthRefreshInterceptor(api, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: false
});

export default api;
