import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { HomeAppContext } from "../Home";
import Slide from "@mui/material/Slide";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import AssistantChatForm from "./AssistantChatForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialEntry = {
  assistantId: "",
  assistantName: "",
  prompt: "",
  fileIds: [],
  continueThread: false,
  runId: "",
  threadId: ""
};

export default function RunAssistant({ open, setOpen, onCreate, assistant }) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const [internalEntry, setInternalEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [aiResponse, setAIResponse] = useState(null);
  const [continueThread, setContinueThread] = useState(false);
  const [assistantRunId, setAssistantRunId] = useState("");
  const [assistantThreadId, setAssistantThreadId] = useState("");

  React.useEffect(() => {
    if (open) {
      setSubmitted(false);
      setErrors([]);
      setInternalEntry({
        ...initialEntry,
        assistantId: assistant.id,
        assistantName: assistant.name
      });
      setLoading(false);
      setAIResponse(null);
      setContinueThread(false);
    } else {
      setInternalEntry(null);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  };

  const handleRunAssistant = () => {
    setLoading(true);
    const runAssistantRequest = {
      assistantName: internalEntry.assistantName,
      assistantId: internalEntry.assistantId,
      prompt: internalEntry.prompt,
      fileIds: internalEntry.fileIds,
      continueThread: false
    };
    homeDispatch({
      type: "NOTIFICATION",
      data: {
        message: "Running AI Assistant " + internalEntry.assistantName,
        loading: true
      }
    });
    api
      .post(`/api/ai-assistants/run`, runAssistantRequest)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: "Running AI Assistant " + internalEntry.assistantName,
            type: "success"
          }
        });
        setContinueThread(true);
        setAIResponse(response.data.aiResponse);
        setAssistantRunId(response.data.runId);
        setAssistantThreadId(response.data.threadId);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  const handleContinueThread = () => {
    setLoading(true);
    const runAssistantRequest = {
      assistantName: internalEntry.assistantName,
      assistantId: internalEntry.assistantId,
      prompt: internalEntry.prompt,
      fileIds: internalEntry.fileIds,
      runId: assistantRunId,
      threadId: assistantThreadId,
      continueThread: true
    };
    homeDispatch({
      type: "NOTIFICATION",
      data: {
        message: "Running AI Assistant " + internalEntry.assistantName,
        loading: true
      }
    });
    api
      .post(`/api/ai-assistants/run`, runAssistantRequest)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: "Running AI Assistant " + internalEntry.assistantName,
            type: "success"
          }
        });
        setContinueThread(true);
        setAIResponse(response.data.aiResponse);
        setInternalEntry((prevState) => ({
          ...prevState,
          assistantRunId: response.data.assistantRunId,
          assistantThreadId: response.data.assistantThreadId
        }));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  return internalEntry === null ? null : (
    <Dialog
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-create-entry"
    >
      <DialogTitle id="alert-dialog-create-entry">
        {'Run Assistant "' + internalEntry.assistantName + '"'}
      </DialogTitle>
      <DialogContent>
        <AssistantChatForm
          entity={internalEntry}
          errors={errors}
          setEntity={setInternalEntry}
          result={aiResponse}
          loading={loading}
          submitted={submitted}
          setSaveDisabled={setSaveDisabled}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        {continueThread === false ? (
          <Button
            onClick={handleRunAssistant}
            color="primary"
            variant="outlined"
            autoFocus
            disabled={loading || saveDisabled}
          >
            Run Assistant
          </Button>
        ) : (
          <Button
            onClick={handleContinueThread}
            color="primary"
            variant="outlined"
            autoFocus
            disabled={loading || saveDisabled}
          >
            Continue Thread
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
