import Form, { UserFormMode } from "./Form";
import React, { useContext, useState } from "react";

import { HomeAppContext } from "../../Home";
import api from "../../../Services/api";
import { errorManagement } from "../../../Services/errorManagement";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide
} from "@mui/material";
import UserAuthType from "./UserAuthType";
import ServiceAccountDetails from "./ServiceAccountDetails";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialEntry = {
  fullName: "",
  email: "",
  role: { id: 4 },
  picture: "",
  pictureChanged: false,
  active: true,
  sendWelcomeMessage: false,
  authType: { id: UserAuthType.ServiceAccount }
};

const CreateServiceAccount = ({ open, setOpen, onCreate }) => {
  const { homeDispatch } = useContext(HomeAppContext);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const [internalEntry, setInternalEntry] = useState(null);
  const [newServiceAccount, setNewServiceAccount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  React.useEffect(() => {
    if (open) {
      setNewServiceAccount(null);
      setSubmitted(false);
      setErrors([]);
      setInternalEntry(initialEntry);
      setLoading(false);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  };

  const handleCloseSave = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Creating User", loading: true }
    });
    setSubmitted(true);
    setLoading(true);
    const newUser = {
      fullName: internalEntry.fullName,
      roleId: internalEntry.role !== "" ? internalEntry.role.id : 0,
      picture: internalEntry.pictureChanged ? internalEntry.picture : null,
      active: internalEntry.active
    };

    api
      .post(`/api/users/service-accounts`, newUser)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "Service account created", type: "success" }
        });
        setLoading(false);
        setNewServiceAccount(response.data);
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  const handleCloseShowServiceAccount = () => {
    setOpen(false);
    onCreate();
  };

  return internalEntry === null ? null : (
    <Dialog
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-create-entry"
    >
      <DialogTitle id="alert-dialog-create-entry">
        {"New Service Account User"}
      </DialogTitle>
      <DialogContent>
        {newServiceAccount !== null ? (
          <ServiceAccountDetails serviceAccount={newServiceAccount} />
        ) : (
          <Form
            mode={UserFormMode.NEW}
            entity={internalEntry}
            errors={errors}
            setEntity={setInternalEntry}
            loading={loading}
            submitted={submitted}
            setSaveDisabled={setSaveDisabled}
          />
        )}
      </DialogContent>
      <DialogActions>
        {newServiceAccount === null && (
          <Button onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
        )}
        {newServiceAccount === null && (
          <Button
            onClick={handleCloseSave}
            color="primary"
            autoFocus
            disabled={loading || saveDisabled}
          >
            Save
          </Button>
        )}
        {newServiceAccount !== null && (
          <Button
            onClick={handleCloseShowServiceAccount}
            color="primary"
            autoFocus
            disabled={loading}
          >
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default CreateServiceAccount;
