import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SvgIcon
} from "@mui/material";
import React, { useContext } from "react";
import { PublicClientApplication } from "@azure/msal-browser";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "./Home";
import Link from "@mui/material/Link";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import api from "../Services/api";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { userStore } from "../Services/store";
import { ReactComponent as MsLogo } from "../ms-symbollockup_mssymbol_19.svg";
import msalConfig, { MsalTenants } from "../Services/msalConfig";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  grid: {
    marginTop: theme.spacing(1)
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  dialog: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 300
  },
  submit: {
    marginTop: 10
  },
  forgotLink: {
    marginTop: 15,
    textAlign: "right"
  },
  microsoftLoginButton: {
    textTransform: "none",
    backgroundColor: "#fff",
    color: "black",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "light" ? "rgba(66,133,244,.04)" : "#d2e3fc",
      boxShadow: "none",
      borderColor: "#d2e3fc",
      outline: "none"
    },
    marginTop: "5px",
    width: "250px",
    border: "1px solid #dadce0",
    borderRadius: "4px"
  }
}));

const MsIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <MsLogo />
    </SvgIcon>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const PasswordResetDialog = ({ open, setOpen }) => {
  const { homeDispatch } = useContext(HomeAppContext);
  const [email, setEmail] = React.useState("");
  const [valid, setValid] = React.useState(true);
  const [emailError, setEmailError] = React.useState("");
  const validRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValidEmail = email.match(validRegex) !== null;
  const [working, setWorking] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      setEmail("");
      setValid(true);
      setEmailError("");
      setEmailSent(false);
    }
  }, [open]);

  const onEmailChange = (event) => setEmail(event.target.value);

  const handleCloseSend = () => {
    sendPasswordResetLink();
  };
  const handleClose = () => {
    if (!working) setOpen(false);
  };

  const validateFields = () => {
    if (!isValidEmail) setEmailError("Incorrect email address");
    setValid(isValidEmail);
    return isValidEmail;
  };

  const sendPasswordResetLink = () => {
    if (validateFields()) {
      setWorking(true);
      const params = {
        email: email
      };
      homeDispatch({
        type: "NOTIFICATION",
        data: { message: "Preparing instructions", loading: true }
      });
      api
        .post("/api/me/resetPassword/send", params)
        .then(function (response) {
          setEmailSent(true);
          homeDispatch({
            type: "NOTIFICATION",
            data: {
              close: true
            }
          });
          setWorking(false);
        })
        .catch((error) => {
          setWorking(false);
          setValid(false);
          if (error.response !== undefined) {
            setEmailError(error.response.data);
            homeDispatch({
              type: "NOTIFICATION",
              data: { message: error.response.data }
            });
          } else {
            console.log(error);
            homeDispatch({
              type: "NOTIFICATION",
              data: { message: "Something was not right" }
            });
          }
        });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-reset-title"
    >
      {emailSent ? (
        <React.Fragment>
          <DialogTitle id="alert-dialog-reset-title">
            Password reset instructions
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom>
              We will be sending instructions on how to reset your password
              shortly.
            </Typography>
            <Typography gutterBottom>
              Please check your email in a couple of minutes at {email}
            </Typography>
          </DialogContent>
          <DialogActions>
            {emailSent}
            <Button onClick={handleClose} disabled={working}>
              close
            </Button>
          </DialogActions>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <DialogTitle id="alert-dialog-reset-title">
            Forgot your Password?
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom>
              Enter your email and we will send you instructions on how to reset
              your password
            </Typography>
            <TextField
              fullWidth
              variant={"outlined"}
              value={email}
              onChange={onEmailChange}
              error={!valid}
              placeholder="Your user email address"
              helperText={emailError}
            />
          </DialogContent>
          <DialogActions>
            {emailSent}
            <Button onClick={handleClose} disabled={working}>
              Cancel
            </Button>
            <Button
              onClick={handleCloseSend}
              color="primary"
              autoFocus
              disabled={!isValidEmail || working}
            >
              Send Instructions
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
};

const LoginForm = ({
  classes,
  userEmail,
  onEmailChange,
  userPassword,
  onPasswordChange,
  ClientLogin,
  onForgotPassword,
  failureLogin,
  msLoginClick
}) => {
  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      ClientLogin();
      event.preventDefault();
    }
  };

  const onResponse = (creds) => {
    console.log(creds);
  };

  return (
    <Grid
      container
      className={classes.grid}
      justifyContent="center"
      spacing={2}
      direction={"column"}
    >
      <Grid item>
        <Grid container spacing={2} justifyContent={"center"}>
          {/* <Grid item sm={12}>
                        <TextField
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={userEmail}
                            onChange={onEmailChange}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onKeyPress={onKeyPress}
                            value={userPassword}
                            onChange={onPasswordChange}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={ClientLogin}
                        >
                            Log In
                        </Button>
                        <div className={classes.forgotLink}>
                            {" "}
                            <Link variant={"body2"} href="#" onClick={onForgotPassword}>
                                Forgot your password?
                            </Link>
                        </div>
                    </Grid> */}
          <Grid item sm={12}>
            <Button
              className={classes.microsoftLoginButton}
              onClick={(event) => msLoginClick(MsalTenants.Default)}
              startIcon={<MsIcon />}
            >
              Sign in with Microsoft
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function Login() {
  const { homeDispatch } = useContext(HomeAppContext);
  const [service, setService] = React.useState("");
  const [working, setWorking] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const [userPassword, setUserPassword] = React.useState("");
  const [openReset, setOpenReset] = React.useState(false);
  const history = useHistory();

  const onEmailChange = (event) => setUserEmail(event.target.value);
  const onPasswordChange = (event) => setUserPassword(event.target.value);

  React.useEffect(() => {
    homeDispatch({ type: "UPDATE_CURRENT_LOCATION", data: "Log in" });
  }, [homeDispatch]);

  const getSystemToken = (authentication, url, params, redirect) => {
    setService(authentication);
    setStatus("Logging into the system");
    setWorking(true);
    api
      .post(url, params)
      .then(function (response) {
        if (response.status === 200) {
          const data = response.data;
          userStore.token = data.token;
          userStore.refresh_token = data.refreshToken;
          setStatus("Loading user profile");
          api.get(`/api/me/profile`).then((response) => {
            userStore.user_profile = response.data;
            homeDispatch({
              type: "UPDATE_USER_PROFILE",
              data: userStore.user_profile
            });
            history.push(redirect);
          });
        }
      })
      .catch(function (error) {
        setWorking(false);
        if (error.response !== undefined) {
          homeDispatch({
            type: "NOTIFICATION",
            data: { message: error.response.data }
          });
        } else {
          homeDispatch({
            type: "NOTIFICATION",
            data: { message: "Network error" }
          });
        }
      });
  };

  const ClientLogin = () => {
    const url = "/api/login/internal";
    const params = {
      UserName: userEmail,
      Password: userPassword
    };
    const redirect = "/";

    getSystemToken("Client", url, params, redirect);
  };

  const classes = useStyles();

  const onForgotPassword = () => {
    setOpenReset(true);
  };

  const failureLogin = (response) => {
    console.log(response);
  };

  const logInAzure = async (tenant = MsalTenants.Default) => {
    setStatus("Logging with Azure credentials");
    setWorking(true);
    sessionStorage.clear();
    const config = msalConfig(tenant);
    const msalInstance = new PublicClientApplication(config);
    await msalInstance.initialize();
    const requestObj = {
      scopes: ["user.read", "User.Read.All"]
    };

    try {
      const loginResponse = await msalInstance.loginPopup(requestObj);
      const url = "/api/login/microsoft";
      const redirect = "/";
      const params = {
        accessToken: loginResponse.accessToken,
        idToken: loginResponse.idToken
      };
      getSystemToken("Microsoft", url, params, redirect);
    } catch (error) {
      setWorking(false);
      console.log(error);
      sessionStorage.clear();
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <PasswordResetDialog open={openReset} setOpen={setOpenReset} />
      <Modal
        className={classes.modal}
        aria-labelledby="loading-dialog"
        open={working}
      >
        <Grid
          className={classes.dialog}
          container
          direction={"column"}
          spacing={4}
          justifyContent={"space-around"}
          alignItems={"center"}
        >
          <Grid item>
            <Typography>Login with {service}</Typography>
          </Grid>
          <Grid item>
            <Typography>{status}</Typography>
          </Grid>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </Modal>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Grid container direction={"column"} spacing={4} alignItems={"center"}>
          <Grid item>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Log in
            </Typography>
          </Grid>

          <Grid item>
            <LoginForm
              classes={classes}
              userEmail={userEmail}
              onEmailChange={onEmailChange}
              userPassword={userPassword}
              onPasswordChange={onPasswordChange}
              ClientLogin={ClientLogin}
              onForgotPassword={onForgotPassword}
              failureLogin={failureLogin}
              msLoginClick={logInAzure}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
