import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { useContext } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Slider from "@mui/material/Slider";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import _ from "lodash";

export const AIEngineFormMode = {
  NEW: "new",
  EDIT: "edit"
};

export default function Form({
  mode = AIEngineFormMode.NEW,
  entity = null,
  errors = [],
  setEntity,
  engineFamilies = [],
  loading,
  submitted,
  setSaveDisabled
}) {
  const onInputChange = (event, property) =>
    setEntity({ ...entity, [property]: event.target.value });

  const onTemperatureChange = (event, newValue) => {
    setEntity({ ...entity, temperature: newValue });
  };

  const onEngineFamilyChange = (value, property) => {
    setEntity({ ...entity, [property]: value });
  };

  const onCostChange = (value, property) =>
    setEntity({ ...entity, [property]: value });

  const onCheckChange = (event, property) =>
    setEntity({ ...entity, [property]: event.target.checked });

  return entity === null ? null : (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <TextField
                required
                autoComplete="new-name"
                fullWidth
                label="Name"
                value={entity.groupName}
                onChange={(event) => onInputChange(event, "groupName")}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                required
                autoComplete="new-description"
                fullWidth
                label="Description"
                value={entity.description}
                onChange={(event) => onInputChange(event, "description")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
