import { Collapse, TableCell, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import EntryDetails from "./EntryDetails";
import EntryDetailsFailed from "./EntryDetailsFailed";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { dayjs } from "../../Services/dateUtil";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";

const Entry = ({   entry,
  allowActions = true,
  isAdmin = false,
  onEdit,
  onRemove }) => {
  const [show, setShow] = useState(false);
  const toggle = () => {
    setShow(prevShow => !prevShow);
  };

  const handleClickEdit = (event) => {
    onEdit(event, entry);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item>
              <IconButton onClick={toggle} size="small">
                {show ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              {entry.groupName}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>{entry.description}</TableCell>
        <TableCell>{entry.groupId}</TableCell>
        <TableCell>{entry.createdBy}</TableCell>
        <TableCell>{dayjs.utc(entry.createdAt).local().format("lll")}</TableCell>
        <IconButton
                size={"small"}
                color="secondary"
                aria-label="edit engine"
                component="span"
                onClick={handleClickEdit}
                disabled={!allowActions}
                title="Edit engine"
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
        </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Grid container alignItems={"center"}>
            <Grid item sm={12}>
              <Collapse in={show} mountOnEnter unmountOnExit>
                <Box>
                  {entry.aiEngines && entry.aiEngines.length > 0 ? (
                    entry.aiEngines.map((history, index) => (
                      <React.Fragment key={history.id}>
                        <EntryDetails entry={history} />
                        {index < entry.aiEngines.length - 1}
                      </React.Fragment>
                    ))
                  ) : <span>No AI Engines</span>}
                </Box>
              </Collapse>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Entry;
