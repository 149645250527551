import { CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import { dayjs } from "../../Services/dateUtil";

const RenderField = ({ label, value }) => (
  <Grid container spacing={2} alignItems="center">
    <Grid item sm={4}><b>{label}</b></Grid>
    <Grid item sm={8}>{value}</Grid>
  </Grid>
);

const parProperties = (string) => {
  try {
    const json = JSON.parse(string);
    return formatText(JSON.stringify(json, null, "\t"));
  } catch (e) {
    return string;
  }
};

const EntryDetailsFailed = ({ entry }) => (
  <Box p={2}>
    <RenderField label="Id" value={entry.id} />
    <RenderField label="Datasets" value={entry.datasets} />
    <RenderField label="Temperature" value={entry.temperature} />
    <RenderField label="IsJson" value={entry.isJson !== undefined ? entry.isJson.toString() : "N/A"}/>
    <RenderField label="CallbackUrl" value={entry.callbackUrl} />
    <RenderField label="ErrorMessage" value={entry.errorMessage} />
    <div style={{ whiteSpace: "pre-wrap" }}><RenderField label="Prompt" value={parProperties(entry.prompt)} /></div>
  </Box>
);

const EntryDetails = ({ entry }) => {
  const { homeDispatch } = useContext(HomeAppContext);
  const [entryData, setEntryData] = useState(entry);
  const [loading, setLoading] = useState(false);

  if (loading) {
    return <CircularProgress />;
  }

  if (!entryData) {
    return <Typography variant="body2">No data available</Typography>;
  }

  return (
    <Grid container spacing={4} className="Details">
      <Grid item sm={12}>
        <EntryDetailsFailed entry={entryData} />
      </Grid>
    </Grid>
  );
};

export default EntryDetails;
