import Form, { AIEngineFormMode, UserFormMode } from "./Form";
import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { HomeAppContext } from "../Home";
import Slide from "@mui/material/Slide";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Edit({ open, setOpen, entry, onEdit }) {
  const { homeDispatch } = useContext(HomeAppContext);

  const [internalEntry, setInternalEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState([]);
  const [connectionNeedsTested, setConnectionNeedsTested] = useState(true);
  const [familiesFromApi, setFamiliesFromApi] = useState([]);
  const [groupsFromApi, setGroupsFromApi] = useState([]);

  React.useEffect(() => {
    if (entry !== null && open) {
      setInternalEntry(entry);
      setErrors([]);
      setLoading(false);
      setSubmitted(false);
      setConnectionNeedsTested(true);
      getEngineFamilies();
      getEngineGroups();
    } else {
      setInternalEntry(null);
    }
  }, [entry, open]);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  };

  const handleTestConnection = () => {
    const testDto = {
      engineName: internalEntry.engineName,
      apiUrl: internalEntry.apiUrl,
      apiToken: internalEntry.apiToken,
      engineFamily: internalEntry.engineFamily
    };
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Testing AI Engine connection", loading: true }
    });
    api
      .post("/api/ai-engines/test-connection", testDto)
      .then((r) => {
        if (r.data) {
          homeDispatch({
            type: "NOTIFICATION",
            data: {
              message: "Testing AI Engine connection success",
              type: "success"
            }
          });
          setConnectionNeedsTested(false);
        }
      })
      .catch((error) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message:
              "Testing AI Engine connection failed: " + error.response.data,
            type: "error"
          }
        });
      });
  };

  const handleCloseSave = () => {
    const updateEngine = {
      id: internalEntry.id,
      engineName: internalEntry.engineName,
      apiUrl: internalEntry.apiUrl,
      apiToken: internalEntry.apiToken,
      description: internalEntry.description,
      isAvailableToTeam: internalEntry.isAvailableToTeam,
      apiCost: internalEntry.apiCost,
      engineFamily: internalEntry.engineFamily,
      temperature: internalEntry.temperature,
      engineGroup: internalEntry.engineGroup,
      maxTokens: internalEntry.maxTokens,
    };

    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Updating " + internalEntry.engineName, loading: true }
    });
    setSubmitted(true);
    setLoading(true);
    api
      .put(`/api/ai-engines/${updateEngine.id}`, updateEngine)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: internalEntry.engineName + " updated",
            type: "success"
          }
        });
        setOpen(false);
        setLoading(false);
        onEdit();
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  const getEngineFamilies = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Fetching engine families", loading: true }
    });
    api
      .get("/api/ai-engines/family/dropdown")
      .then((r) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: "Engine families fetched successfuly",
            type: "success"
          }
        });
        setFamiliesFromApi(r.data);
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  };

  const getEngineGroups = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Fetching engine families", loading: true }
    });
    api
      .get("/api/ai-prompts/group/dropdown")
      .then((r) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: "Engine families fetched successfuly",
            type: "success"
          }
        });
        setGroupsFromApi(r.data);
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  };

  return internalEntry === null ? null : (
    <Dialog
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-edit-entry"
    >
      <DialogTitle id="alert-dialog-edit-entry">{"Edit AI Engine"}</DialogTitle>
      <DialogContent>
        <Form
          mode={AIEngineFormMode.EDIT}
          entity={internalEntry}
          errors={errors}
          setEntity={setInternalEntry}
          loading={loading}
          submitted={submitted}
          setSaveDisabled={setSaveDisabled}
          engineFamilies={familiesFromApi}
          engineGroups={groupsFromApi}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        {connectionNeedsTested === true ? (
          <Button
            onClick={handleTestConnection}
            variant="outlined"
            color="primary"
          >
            Test Connection
          </Button>
        ) : (
        <Button
            onClick={handleCloseSave}
            color="primary"
            variant="outlined"
            autoFocus
            disabled={loading || saveDisabled}
          >
            Save Engine
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
