import React from "react";
import CopyValue from "../../General/CopyValue";
import Grid from "@mui/material/Grid";
import TabularField from "../../General/TabularField";

const ServiceAccountDetails = ({ serviceAccount }) => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <TabularField
          label={"Name"}
          direction={"column"}
          value={serviceAccount.fullName}
        />
      </Grid>
      <Grid item sm={12}>
        <TabularField
          label={"User name"}
          direction={"column"}
          value={<CopyValue value={serviceAccount.email} />}
        />
      </Grid>
      <Grid item sm={12}>
        <TabularField
          label={"Password"}
          direction={"column"}
          value={<CopyValue value={serviceAccount.generatedPassword} />}
        />
      </Grid>
    </Grid>
  );
};

export default ServiceAccountDetails;
