import Form, { AIAssistantFormMode } from "./Form";
import React, { useContext, useState, useCallback } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { HomeAppContext } from "../Home";
import Slide from "@mui/material/Slide";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Edit({ open, setOpen, entry, onEdit }) {
  const { homeDispatch } = useContext(HomeAppContext);

  const [internalEntry, setInternalEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState([]);

  React.useEffect(() => {
    if (entry !== null && open) {
      setLoading(true);
      homeDispatch({
        type: "NOTIFICATION",
        data: { message: "Fetching assistant details", loading: true }
      });
      api
        .get(`/api/ai-assistants/${entry.id}`)
        .then((r) => {
          homeDispatch({
            type: "NOTIFICATION",
            data: {
              message: "Assistant details fetched successfuly",
              type: "success"
            }
          });
          setInternalEntry({
            ...r.data,
            deployment: {
              id: entry.model,
              name: entry.model
            }
          });
        })
        .catch((error) => {
          errorManagement.formErrors(error, homeDispatch);
        })
        .finally(() => {
          setErrors([]);
          setSubmitted(false);
          setLoading(false);
        });
    } else {
      setInternalEntry(null);
    }
  }, [entry, open]);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  };

  const handleCloseSave = () => {
    const updateAssistant = {
      id: internalEntry.id,
      model:
        internalEntry.deployment?.id !== undefined
          ? internalEntry.deployment.id
          : internalEntry.model,
      files: [
        ...internalEntry.files?.map((f) => ({
          fileId: f.fileId,
          name: f.name
        })),
        ...internalEntry.assistantFiles?.map((f) => ({
          fileId: -1,
          assistantFileId: f.assistantFileId,
          name: f.fileName
        }))
      ],
      instructions: internalEntry.instructions,
      description: internalEntry.description,
      name: internalEntry.name,
      codeInterpreter: internalEntry.codeInterpreter
    };

    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Updating " + internalEntry.name, loading: true }
    });
    setSubmitted(true);
    setLoading(true);
    api
      .put(`/api/ai-assistants/${updateAssistant.id}`, updateAssistant)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: internalEntry.name + " updated",
            type: "success"
          }
        });
        setOpen(false);
        setLoading(false);
        onEdit();
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  return internalEntry === null ? null : (
    <Dialog
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-edit-entry"
    >
      <DialogTitle id="alert-dialog-edit-entry">{"Edit AI Engine"}</DialogTitle>
      <DialogContent>
        <Form
          mode={AIAssistantFormMode.EDIT}
          entity={internalEntry}
          errors={errors}
          setEntity={setInternalEntry}
          loading={loading}
          submitted={submitted}
          setSaveDisabled={setSaveDisabled}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleCloseSave}
          color="primary"
          variant="outlined"
          autoFocus
          disabled={loading || saveDisabled}
        >
          Save Assistant
        </Button>
      </DialogActions>
    </Dialog>
  );
}
