import { TextField } from "@mui/material";
import React, { useContext, useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../Home";
import FileUploader from "../General/FileUploader";
import InputLabel from "@mui/material/InputLabel";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import { set } from "lodash";

export const DatasetFormMode = {
  NEW: "new",
  EDIT: "edit"
};

export default function Form({
  mode = DatasetFormMode.NEW,
  open = false,
  entity = null,
  errors = [],
  variables = [],
  setEntity,
  loading,
  submitted,
  setSaveDisabled
}) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [isCSVRead, setIsCSVRead] = useState(false);
  const isCSVBeingRead = useRef(false);
  const [csvValues, setCsvValues] = useState([]);
  const [csvVariables, setCsvVariables] = useState([]);

  const onInputChange = (event, property) =>
    setEntity({ ...entity, [property]: event.target.value });

  const onValuesInputChange = (event, property) => {
    if (event.target.value !== "") {
      prepareValuesAndVariables(event.target.value);
    } else {
      setEntity((prevEntity) => ({
        ...prevEntity,
        variables: [],
        values: []
      }));
    }
  };

  const prepareValuesAndVariables = (content) => {
    if (content.includes("\t")) {
      const csvConverted = content.replace(/\t/g, ";");
      const valueLines = csvConverted.split("\n");
      const variables = valueLines[0].split(";");
      valueLines.shift();
      setEntity((prevEntity) => ({
        ...prevEntity,
        variables: variables
      }));
      if (valueLines[valueLines.length - 1] === "") {
        valueLines.pop();
      }
      setEntity((prevEntity) => ({
        ...prevEntity,
        values: valueLines
      }));
    }
  };

  const onVariableInputChange = (value, property) => {
    setEntity({ ...entity, [property]: value });
  };

  useEffect(() => {
    if (isCSVRead) {
      setEntity((prevEntity) => ({
        ...prevEntity,
        variables: csvVariables,
        values: csvValues
      }));
    }
  }, [isCSVRead]);

  const onFileUploaded = (value) => {
    if (value.length > 0 && open) {
      if (
        value[0].uploading === false &&
        value[0].progress === 100 &&
        !isCSVRead &&
        !isCSVBeingRead.current
      ) {
        isCSVBeingRead.current = true;
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "Reading from CSV", loading: true }
        });
        api
          .get("/api/datasets/csv-content/" + value[0].id)
          .then((r) => {
            homeDispatch({
              type: "NOTIFICATION",
              data: { message: "CSV read successfuly", type: "success" }
            });
            setCsvVariables(r.data.headers);
            setCsvValues(r.data.rows);
            setIsCSVRead(true);
          })
          .catch((error) => errorManagement.formErrors(error, homeDispatch))
          .finally(() => {
            isCSVBeingRead.current = false;
          });
      }
    } else {
      setEntity((prevEntity) => ({
        ...prevEntity,
        fileIds: [],
        variables: [],
        values: []
      }));
      setIsCSVRead(false);
    }
    setEntity({ ...entity, fileIds: value });
  };

  return entity === null ? null : (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item sm={12}>
          <Grid item sm={5}>
            <TextField
              required
              label="New Dataset"
              fullWidth
              value={entity.name}
              onChange={(event) => onInputChange(event, "name")}
            />
          </Grid>
        </Grid>

        {/* <Grid item sm={12}>
          <Grid item sm={8}>
            <CustomAutocomplete
              value={entity.prompts}
              setValue={(value) => onMultiInputChange(value, "prompts")}
              related={null}
              onLoading={setSaveDisabled}
              url={"/api/ai-prompts/dropdown"}
              label={"AI Prompts"}
              placeholder={"Select prompt(s)"}
              noOptionsText={"No AI prompt found"}
              loadingText={"Searching"}
              margin={"normal"}
              multiple={true}
              liveOptions={false}
              error={_.isArray(errors.prompts)}
              helperText={_.isArray(errors.prompts) && errors.prompts.join()}
              disableClearable={true}
            />
          </Grid>
        </Grid> */}

        <Grid item sm={12}>
          <Grid item sm={12}>
            <InputLabel>Import Dataset from CSV file</InputLabel>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid item sm={12}>
            <FileUploader
              onFileUploaded={onFileUploaded}
              uploadUrl="/api/files/"
              acceptedFileExtensions=".csv"
              acceptedFileExtensionsMessage=".csv files only"
              maxFiles={1}
            ></FileUploader>
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <Grid item sm={12}>
            <Autocomplete
              required
              freeSolo={true}
              multiple={true}
              disableClearable
              value={entity.variables}
              onChange={(event, value) =>
                onVariableInputChange(value, "variables")
              }
              options={variables.map((variable) => variable.text)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Dataset Variables"
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <Grid item sm={12}>
            <TextField
              label="Dataset Values"
              fullWidth
              value={entity.values.join("\n")}
              multiline
              rows={8}
              onChange={(event) => onValuesInputChange(event, "values")}
            />
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <Grid item sm={12}>
            <TextField
              label="Dataset Description"
              fullWidth
              value={entity.description}
              multiline
              rows={4}
              onChange={(event) => onInputChange(event, "description")}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
