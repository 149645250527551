import { TextField } from "@mui/material";
import React, { useContext, useState, useRef, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import CustomAutocomplete from "../General/CustomAutocomplete";
import FileUploader from "../General/FileUploader";
import Slider from "@mui/material/Slider";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import { Checkbox, FormControlLabel } from "@mui/material";

export const AIPromptFormMode = {
  NEW: "new",
  EDIT: "edit"
};

export default function Form({
  mode = AIPromptFormMode.NEW,
  entity = null,
  errors = [],
  tags = [],
  engineFamilies = [],
  setEntity,
  loading,
  submitted,
  setSaveDisabled
}) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [variables, setVariables] = useState([]);
  const textFieldRef = useRef(null);

  const onInputChange = (event, property) =>
    setEntity({ ...entity, [property]: event.target.value });

  const onDatasetChange = (value, property) => {
    if (value !== "") {
      setEntity({ ...entity, [property]: value });
    }
  };

  const onMultiInputChange = (value, property) => {
    setEntity({ ...entity, [property]: value });
  };

  const onTagInputChange = (value, property) => {
    setEntity({ ...entity, [property]: value });
  };

  const onTemperatureChange = (event, newValue) => {
    setEntity({ ...entity, temperature: newValue });
  };

  const onValueChange = (value, property) =>
    setEntity({ ...entity, [property]: value });

  const getDatasetVariables = (datasetId) => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Fetching dataset variables", loading: true }
    });
    api
      .get(`/api/datasets/variables/${datasetId}/dropdown`)
      .then((r) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: "Dataset variables fetched successfuly",
            type: "success"
          }
        });
        setVariables(r.data);
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  };

  useEffect(() => {
    if (entity.dataset && entity.dataset.id !== 0 && entity.dataset !== "") {
      getDatasetVariables(entity.dataset.id);
    }
  }, [entity.dataset]);

  const insertTextAtCursor = (text) => {
    const textField = textFieldRef.current;
    if (!textField) return;

    const startPos = textField.selectionStart;
    const endPos = textField.selectionEnd;

    const newValue =
      textField.value.substring(0, startPos) +
      text +
      textField.value.substring(endPos, textField.value.length);

    textField.value = newValue;
    setEntity({ ...entity, prompt: newValue });

    textField.selectionStart = startPos + text.length;
    textField.selectionEnd = startPos + text.length;
  };

  return entity === null ? null : (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item sm={12}>
          <Grid item sm={5}>
            <TextField
              required
              label="New Prompt"
              fullWidth
              value={entity.promptName}
              onChange={(event) => onInputChange(event, "promptName")}
            />
          </Grid>
        </Grid>

        {/* <Grid item sm={4}>
          <CustomAutocomplete
            value={entity.engineFamily}
            setValue={(value) => onEngineFamilyChange(value, "engineFamily")}
            related={null}
            onLoading={setSaveDisabled}
            url={"/api/ai-engines/family/dropdown"}
            label={"Engine Family"}
            placeholder={"Select an engine family"}
            noOptionsText={"No AI engine found"}
            loadingText={"Searching"}
            margin={"normal"}
            multiple={false}
            liveOptions={false}
            error={_.isArray(errors.engineFamilies)}
            helperText={
              _.isArray(errors.engineFamilies) && errors.engineFamilies.join()
            }
            disableClearable={true}
          />
        </Grid> */}

        <Grid item sm={12}>
          <Grid item sm={8}>
            <CustomAutocomplete
              value={entity.aiEngines}
              setValue={(value) => onMultiInputChange(value, "aiEngines")}
              related={null}
              onLoading={setSaveDisabled}
              url={`/api/ai-engines/dropdown`}
              label={"AI Engines"}
              placeholder={"Select an engine"}
              noOptionsText={"No AI engine found"}
              loadingText={"Searching"}
              margin={"normal"}
              multiple={true}
              liveOptions={true}
              error={_.isArray(errors.aiEngines)}
              helperText={
                _.isArray(errors.aiEngines) && errors.aiEngines.join()
              }
              disableClearable={true}
            />
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid item sm={9}>
              <TextField
                label="Initial Prompt"
                fullWidth
                value={entity.prompt}
                multiline
                rows={4}
                onChange={(event) => onInputChange(event, "prompt")}
                inputRef={textFieldRef}
              />
            </Grid>
            <Grid item sm={3}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <CustomAutocomplete
                    value={entity.dataset}
                    setValue={(value) => onDatasetChange(value, "dataset")}
                    related={null}
                    onLoading={setSaveDisabled}
                    url={"/api/datasets/dropdown"}
                    label={"Datasets"}
                    placeholder={"Select a dataset"}
                    noOptionsText={"No datasets found"}
                    loadingText={"Searching"}
                    multiple={false}
                    liveOptions={false}
                    error={_.isArray(errors.dataset)}
                    helperText={
                      _.isArray(errors.dataset) && errors.dataset.join()
                    }
                    disableClearable={true}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Grid container spacing={2}>
                    {entity.dataset &&
                      variables.map((variable) => (
                        <Grid item>
                          <Chip
                            label={variable.text}
                            key={variable.id}
                            size="small"
                            onClick={() =>
                              insertTextAtCursor("%" + variable.text + "%")
                            }
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <Grid item sm={12}>
            <TextField
              label="Prompt Description"
              fullWidth
              value={entity.description}
              multiline
              rows={4}
              onChange={(event) => onInputChange(event, "description")}
            />
          </Grid>
        </Grid>

        <Grid item sm={12}>
          <Grid item sm={8}>
            <InputLabel>Temperature: {entity.temperature}</InputLabel>

            <Slider
              value={entity.temperature}
              min={0.0}
              step={0.1}
              max={1}
              onChange={onTemperatureChange}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
            />
          </Grid>
        </Grid>

        {/* <Grid item sm={12}>
          <Grid item sm={12}>
            <InputLabel>Prompt File</InputLabel>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid item sm={12}>
            <FileUploader
              onFileUploaded={onFileUploaded}
              uploadUrl="/api/files/"
              acceptedFileExtensions=".docx, .doc, .pdf, .txt"
              acceptedFileExtensionsMessage=".docx, .doc, .pdf, .txt"
            ></FileUploader>
          </Grid>
        </Grid> */}

          <Grid item sm={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={entity.isJson}
                  onChange={(event) =>
                    onValueChange(event.target.checked, "isJson")
                  }
                />
              }
              label="Output as JSON"
            />
          </Grid>

        <Grid item sm={12}>
          <Grid item sm={12}>
            <Autocomplete
              freeSolo={true}
              multiple={true}
              disableClearable
              value={entity.tags}
              onChange={(event, value) => onTagInputChange(value, "tags")}
              options={tags.map((tag) => tag.text)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Prompt Tags"
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
