import {
    Checkbox,
    Collapse,
    FormControl,
    FormControlLabel,
    FormHelperText,
    TextField,
    Typography,
} from "@mui/material";
import React, {useContext, useState} from "react";

import CustomAutocomplete from "../../General/CustomAutocomplete";
import Grid from "@mui/material/Grid";
import {HomeAppContext} from "../../Home";
import PasswordField from "../../General/PasswordField";
import UserPicture from "../../User/UserPicture";
import _ from "lodash";
import api from "../../../Services/api";
import {errorManagement} from "../../../Services/errorManagement";

export const UserFormMode = {
  NEW: "new",
  EDIT: "edit",
};

export default function Form({
  mode = UserFormMode.NEW,
  entity = null,
  errors = [],
  setEntity,
  loading,
  submitted,
  setSaveDisabled,
}) {
  const [validNewPassword, setValidNewPassword] = useState(true);
  const { homeDispatch } = useContext(HomeAppContext);
  const isInternalUser =
    entity === null ? false : entity.authType.name === "Internal";

  const handleChangePicture = (newPicture) => {
    setEntity({ ...entity, picture: newPicture, pictureChanged: true });
  };

  const validEmailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValidEmail = entity
    ? entity.email.match(validEmailRegex) !== null
    : false;

  const validPassword =
    validNewPassword &&
    entity.newPasswordRepeat !== "" &&
    entity.newPassword === entity.newPasswordRepeat;

  const showPasswordError =
    (entity.newPassword !== "" || entity.newPasswordRepeat !== "") &&
    validNewPassword &&
    entity.newPassword !== entity.newPasswordRepeat;

  const onInputChange = (event, property) =>
    setEntity({ ...entity, [property]: event.target.value });

  const onCheckChange = (event, property) =>
    setEntity({ ...entity, [property]: event.target.checked });

  const onValueChange = (value, property) =>
    setEntity({ ...entity, [property]: value });

  const onChangePasswordChange = (event) => {
    const checked = event.target.checked;
    if (!checked) {
      setEntity({
        ...entity,
        changePassword: checked,
        newPassword: "",
        newPasswordRepeat: "",
      });
    } else {
      setEntity({ ...entity, changePassword: checked });
    }
  };

  const onRoleChange = (value) => {
    if (value !== "" && value.id !== entity.role.id)
      api
        .get(`/api/roles/${value.id}`)
        .then((r) => {
          setEntity({
            ...entity,
            role: value,
            profile: { ...entity.profile, profileType: r.data.profileType },
          });
        })
        .catch((error) => {
          errorManagement.formErrors(error, homeDispatch);
        });
  };

  return entity === null ? null : (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <UserPicture
              text={entity.fullName}
              picture={entity.picture}
              onAccept={handleChangePicture}
              disabled={!isInternalUser}
            />
          </Grid>

          <Grid item sm={12}>
            <TextField
              required
              autoComplete="new-name"
              fullWidth
              error={_.isArray(errors.fullName)}
              helperText={_.isArray(errors.fullName) && errors.fullName.join()}
              label="Name"
              value={entity.fullName}
              onChange={(event) => onInputChange(event, "fullName")}
              disabled={loading}
            />
          </Grid>

          <Grid item sm={12}>
            <TextField
              required
              autoComplete="new-email"
              fullWidth
              error={
                _.isArray(errors.email) ||
                (entity.email !== "" && !isValidEmail)
              }
              helperText={_.isArray(errors.email) && errors.email.join()}
              disabled={loading || !isInternalUser}
              label="Email"
              value={entity.email}
              onChange={(event) => onInputChange(event, "email")}
            />
          </Grid>

          {mode === UserFormMode.EDIT ? (
            <React.Fragment>
              <Grid item sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={entity.changePassword}
                      onChange={onChangePasswordChange}
                      name="changePassword"
                      color="primary"
                      disabled={loading || !isInternalUser}
                    />
                  }
                  label="Change password"
                />
              </Grid>
              <Grid item sm={12}>
                <Collapse in={entity.changePassword}>
                  <Grid container>
                    <Grid item sm={12}>
                      <PasswordField
                        value={entity.newPassword}
                        setValue={(value) =>
                          onValueChange(value, "newPassword")
                        }
                        disabled={loading}
                        showError={entity.newPassword !== "" && !validPassword}
                        label={"New Password"}
                        setValid={setValidNewPassword}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        required
                        autoComplete="new-password-repeat"
                        fullWidth
                        error={
                          entity.newPasswordRepeat !== "" && !validPassword
                        }
                        helperText={
                          showPasswordError ? "The passwords are not equal" : ""
                        }
                        type="password"
                        disabled={loading}
                        label="New Password Repeat"
                        value={entity.newPasswordRepeat}
                        onChange={(event) =>
                          onInputChange(event, "newPasswordRepeat")
                        }
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </React.Fragment>
          ) : null}

          <Grid item sm={6}>
            <CustomAutocomplete
              value={entity.role}
              setValue={(value) => onRoleChange(value)}
              related={null}
              onLoading={setSaveDisabled}
              url={"/api/roles/dropdown/form"}
              label={"Role"}
              placeholder={"Select Role"}
              noOptionsText={"No roles found"}
              loadingText={"Searching"}
              error={_.isArray(errors.role)}
              helperText={_.isArray(errors.role) && errors.role.join()}
              liveOptions={false}
              disableClearable={true}
            />
          </Grid>
          <Grid item sm={6}>
            <FormControl margin={"normal"} error={_.isArray(errors.active)}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={entity.active}
                    onChange={(event) => onCheckChange(event, "active")}
                    color="primary"
                    disabled={!isInternalUser}
                  />
                }
                label="User is active"
              />
              <FormHelperText>
                {_.isArray(errors.active) && errors.active.join()}
              </FormHelperText>
            </FormControl>
          </Grid>
          {mode === UserFormMode.NEW ? (
            <React.Fragment>
              <Grid item sm={12}>
                <FormControl
                  margin={"none"}
                  error={_.isArray(errors.sendWelcomeMessage)}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={entity.sendWelcomeMessage}
                        onChange={(event) =>
                          onCheckChange(event, "sendWelcomeMessage")
                        }
                        color="primary"
                        disabled={!isInternalUser}
                      />
                    }
                    label="Send welcome message"
                  />
                  <FormHelperText>
                    {_.isArray(errors.sendWelcomeMessage) &&
                      errors.sendWelcomeMessage.join()}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <Typography variant={"h6"}>Note</Typography>
                <Typography>
                  The system will generate a strong password on user creation
                  and send an email to the associated address.
                </Typography>
                <Typography>
                  The content will include a welcome message and a request to
                  reset the password.
                </Typography>
              </Grid>
            </React.Fragment>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}
