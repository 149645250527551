import { useState } from "react";
import { TableCell, TableRow, Typography, Collapse, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import React from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { GeDeleteIcon } from "../General/GeneralIcons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { dayjs } from "../../Services/dateUtil";
import TabularField from "../General/TabularField";

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 20
  }
}));

const Entry = ({
  entry,
  allowActions = true,
  isAdmin = false,
  onEdit,
  onRemove
}) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const showActions =
    allowActions && (isAdmin ? true : entry.role.name !== "Admin");

  const handleClickEdit = (event) => {
    onEdit(event, entry);
  };

  const handleClickRemove = (event) => {
    onRemove(event, entry);
  };

  const toggle = () => {
    setShow((show) => !show);
  };

  const formatText = (string) =>
    string
      ? string.split("\\n").map((item, key) => {
          return (
            <span key={key}>
              {item}
              <br />
            </span>
          );
        })
      : "";
  const parProperties = (string) => {
    try {
      const json = JSON.parse(string);
      return formatText(JSON.stringify(json, null, "\t"));
    } catch (e) {
      return string;
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "&>td": { borderBottomStyle: "none" } }}>
        <TableCell>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton onClick={toggle} size="small">
                {show ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Typography variant="body2" display="inline">
                {entry.engineName}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.createdBy.fullName}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {dayjs(entry.modifiedAt)
              .utc("z")
              .local()
              .format("YYYY-MM-DD, HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.apiUrl}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.apiToken}
          </Typography>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          {showActions && (
            <React.Fragment>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="edit engine"
                component="span"
                onClick={handleClickEdit}
                disabled={!allowActions}
                title="Edit engine"
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="remove engine"
                component="span"
                onClick={handleClickRemove}
                disabled={!allowActions}
                title="Remove engine"
              >
                <GeDeleteIcon fontSize="inherit" />
              </IconButton>
            </React.Fragment>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={show} mountOnEnter unmountOnExit>
            <Box className={classes.detailsContainer}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <TabularField
                    label={"Engine Family"}
                    labelGridSize={3}
                    value={entry.engineFamily}
                  />
                </Grid>
                <Grid item sm={6}></Grid>
                <Grid item sm={6}>
                  <TabularField
                    label={"Description/Notes"}
                    labelGridSize={3}
                    value={
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {parProperties(entry.description)}
                      </div>
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Entry;
