import { useContext, useState } from "react";
import { TableCell, TableRow, Typography, Collapse, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import React from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { GeEnableIcon } from "../General/GeneralIcons";
import { GeDeleteIcon } from "../General/GeneralIcons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { dayjs } from "../../Services/dateUtil";
import TabularField from "../General/TabularField";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import { LinearProgress } from "@mui/material";
import RunAssistant from "./RunAssistant";

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 20
  }
}));

const Entry = ({
  entry,
  allowActions = true,
  isAdmin = false,
  onEdit,
  onRemove
}) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [showRunAssistant, setShowRunAssistant] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const showActions =
    allowActions && (isAdmin ? true : entry.role.name !== "Admin");
  const { homeDispatch } = useContext(HomeAppContext);

  const handleClickEdit = (event) => {
    onEdit(event, entry);
    if (show) setShow((show) => !show);
  };

  const handleClickRemove = (event) => {
    onRemove(event, entry);
  };

  const toggle = () => {
    if (!show) {
      getFileNames();
    }
    setShow((show) => !show);
  };

  const getFileNames = () => {
    setShowLoading(true);
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Fetching file names for assistant", loading: true }
    });
    api
      .get(`/api/ai-assistants/files/${entry.id}`)
      .then((r) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: "Assistant file names fetched successfuly",
            type: "success"
          }
        });
        setFiles(r.data);
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch))
      .finally(() => {
        setShowLoading(false);
      });
  };

  const handleClickExecute = () => {
    setShowRunAssistant((showRunAssistant) => !showRunAssistant);
  };

  const formatText = (string) =>
    string
      ? string.split("\\n").map((item, key) => {
          return (
            <span key={key}>
              {item}
              <br />
            </span>
          );
        })
      : "";
  const parProperties = (string) => {
    try {
      const json = JSON.parse(string);
      return formatText(JSON.stringify(json, null, "\t"));
    } catch (e) {
      return string;
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "&>td": { borderBottomStyle: "none" } }}>
        <TableCell>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton onClick={toggle} size="small">
                {show ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Typography variant="body2" display="inline">
                {entry.name}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {entry.model}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" display="inline">
            {dayjs(entry.createdAt)
              .utc("z")
              .local()
              .format("YYYY-MM-DD, HH:mm:ss")}
          </Typography>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          {showActions && (
            <React.Fragment>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="execute assistant"
                component="span"
                onClick={handleClickExecute}
                title="Execute assistant"
              >
                <GeEnableIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="edit assistant"
                component="span"
                onClick={handleClickEdit}
                disabled={!allowActions}
                title="Edit Assistant"
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="remove assistant"
                component="span"
                onClick={handleClickRemove}
                disabled={!allowActions}
                title="Remove Assistant"
              >
                <GeDeleteIcon fontSize="inherit" />
              </IconButton>
            </React.Fragment>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={show} mountOnEnter unmountOnExit>
            <Box className={classes.detailsContainer}>
              <Grid container spacing={2}>
                <Grid item sm={9}>
                  <TabularField
                    label={"Instructions"}
                    labelGridSize={3}
                    value={
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {parProperties(entry.instructions)}
                      </div>
                    }
                  />
                </Grid>
                <Grid item sm={3}></Grid>
                <Grid item sm={9}>
                  <TabularField
                    label={"Description"}
                    labelGridSize={3}
                    value={
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {parProperties(entry.description)}
                      </div>
                    }
                  />
                </Grid>
                <Grid item sm={3}></Grid>
                <Grid item sm={9}>
                  <TabularField
                    label={"File(s)"}
                    labelGridSize={3}
                    value={
                      showLoading ? (
                        <LinearProgress />
                      ) : files.length > 0 ? (
                        <div style={{ whiteSpace: "pre-wrap" }}>
                          {parProperties(
                            files.map((f) => f.name).join(", \n\r")
                          )}
                        </div>
                      ) : (
                        "No files associated with this AI Assistant"
                      )
                    }
                  />
                </Grid>
                <Grid item sm={3}></Grid>
                <Grid item sm={9}>
                  <TabularField
                    label={"Code Interpreter"}
                    labelGridSize={3}
                    value={entry.codeInterpreter ? "Enabled" : "Disabled"}
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <RunAssistant
        open={showRunAssistant}
        setOpen={setShowRunAssistant}
        assistant={entry}
      />
    </React.Fragment>
  );
};

export default Entry;
