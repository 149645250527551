import React, { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditLabelDialog = ({
  open,
  setOpen,
  entry,
  onCloseSuccess = null,
  onClose = null
}) => {
  const [internalEntry, setInternalEntry] = useState([]);

  React.useEffect(() => {
    if (open) setInternalEntry(entry);
  }, [open]);

  const onValueChange = (value, property) =>
    setInternalEntry({ ...internalEntry, [property]: value });

  const handleClose = () => {
    setOpen(false);
    if (onClose !== null) onClose();
  };

  const handleCloseSuccess = () => {
    if (onCloseSuccess !== null) {
      onCloseSuccess(internalEntry);
    }
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-edit-label"
      maxWidth={"lg"}
    >
      <DialogTitle id="alert-dialog-edit-label">{"Edit Label"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <TextField
              id="notes-input"
              label="Label"
              value={internalEntry.label}
              onChange={(event) => onValueChange(event.target.value, "label")}
              style={{ minWidth: "300px" }}
            />
          </Grid>

          <Grid item sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={internalEntry.isActive}
                  onChange={(event) =>
                    onValueChange(event.target.checked, "isActive")
                  }
                />
              }
              label="Active Prompt"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleCloseSuccess} color="primary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLabelDialog;
