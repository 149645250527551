import { Collapse, TableCell, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import EntryDetails from "./EntryDetails";
import EntryDetailsFailed from "./EntryDetailsFailed";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { dayjs } from "../../Services/dateUtil";
import Box from "@mui/material/Box";

const Entry = ({ entry }) => {
  const [show, setShow] = useState(false);
  const toggle = () => {
    setShow(prevShow => !prevShow);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item>
              <IconButton onClick={toggle} size="small">
                {show ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              {entry.requestId}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>{entry.projectName}</TableCell>
        <TableCell>{entry.engineName}</TableCell>
        <TableCell>{dayjs.utc(entry.createdAt).local().format("lll")}</TableCell>
        <TableCell>{entry.status}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Grid container alignItems={"center"}>
            <Grid item sm={12}>
              <Collapse in={show} mountOnEnter unmountOnExit>
                <Box>
                  {entry.promptExecutionHistories && entry.promptExecutionHistories.length > 0 ? (
                    entry.promptExecutionHistories.map((history, index) => (
                      <React.Fragment key={history.id}>
                        <EntryDetails entry={history} />
                        {index < entry.promptExecutionHistories.length - 1 && <hr />}
                      </React.Fragment>
                    ))
                  ) : (
                    <EntryDetailsFailed key={entry.id} entry={entry} />
                  )}
                </Box>
              </Collapse>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Entry;
