import React, { useContext, useEffect } from "react";
import Entry from "./Entry";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import MyDataGrid from "../General/Pagination/MyDataGrid";

export default function DepartmentsList(props) {
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const statusValues = [
    { id: -1, text: "NotFound" },
    { id: 0, text: "NotStarted" },
    { id: 1, text: "Running" },
    { id: 2, text: "Completed" },
    { id: 3, text: "Failed" }
  ];
  const isAdmin =
  homeState.userProfile.role === "Admin" ||
  homeState.userProfile.role === "PowerUser";

  const allowActions = isAdmin;

  const tableOptions = {
    columns: [
      {
        name: "Request Id",
        description: "Request Id",
        sortable: true,
        filter: { enabled: false },
        size: 5
      },
      {
        name: "Prompt Name",
        description: "Prompt Name",
        sortable: true,
        filter: { enabled: false },
        size: 4
      },
      {
        name: "Engine Name",
        description: "Engine Name",
        sortable: true,
        filter: { enabled: false },
        size: 3
      },
      {
        name: "Created At",
        description: "Created At",
        sortable: true,
        filter: { enabled: false },
        size: 2
      },
      {
        name: "Status",
        description: "Status",
        sortable: false,
        filter: { enabled: false },
        size: 1
      }
    ],
    defaultSort: [{ name: "Request Id", order: "-" }],
    additionalFilters: [
      {
        name: "RequestId",
        description: "Request Id",
        filter: {
          enabled: true,
          comparison: "==",
          type: "text",
          value: statusValues,
        },
        size: 4
      },
      {
        name: "projectId",
        description: "Project Name",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: "/api/project/dropdown",
            multiple: false,
            liveOptions: false,
            checkbox: false
          }
        },
        size: 2
      },
      {
        name: "engineId",
        description: "Engine Name",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: "/api/aimodel/dropdown",
            multiple: false,
            liveOptions: false,
            checkbox: false
          }
        },
        size: 2
      },
      {
        name: "Status",
        description: "Active Status",
        filter: {
          enabled: true,
          comparison: "==",
          type: "select",
          value: statusValues,
        },
        size: 2
      }
    ]
  };

  useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "Prompt Execution History"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <MyDataGrid
            options={tableOptions}
            url={"/api/ArtificialIntelligence/GetAllRequestsWithHistory"}
            label={"AI prompts"}
            renderItem={(item) => <Entry allowActions={allowActions} key={item.id} entry={item} />}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
