import { Box, Collapse, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GeDeleteIcon, GeEditIcon } from "../../../General/GeneralIcons";
import React, { useContext, useState } from "react";

import ConfirmDialog from "../../../General/ConfirmDialog";
import CustomAvatar from "../../../General/CustomAvatar";
import Edit from "./Edit";
import EntryDetails from "./EntryDetails";
import ExecutedStatus from "./ExecutedStatus";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../../../Home";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import api from "../../../../Services/api";
import { errorManagement } from "../../../../Services/errorManagement";
import moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 20
  },
  alignRight: {
    textAlign: "right"
  }
}));
const RenderUser = React.memo(({ user }) => {
  return (
    <Grid item>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <CustomAvatar
            text={user.fullName}
            picture={user.picture}
            size={"small"}
          />
        </Grid>
        <Grid item>
          <Typography variant="caption" display="inline">
            {user.fullName}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
});

const Entry = ({ entry, refreshItems }) => {
  const classes = useStyles();
  const { homeDispatch } = useContext(HomeAppContext);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [show, setShow] = useState(false);
  const toggle = () => {
    setShow((show) => !show);
  };

  const handleClickDelete = () => {
    setOpenDelete(true);
  };

  const cancelDelete = () => {
    setOpenDelete(false);
  };

  const acceptDelete = () => {
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Deleting", loading: true }
    });
    api
      .delete(`/api/system/ad-hoc-notifications/${entry.id}`)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: { message: "Deleted", type: "success" }
        });
        refreshItems();
      })
      .catch((error) => {
        errorManagement.formErrors(error, homeDispatch);
      });
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item>
              <IconButton onClick={toggle} size="small">
                {show ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </IconButton>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              {entry.toUsers.length === 1 ? (
                <RenderUser user={entry.toUsers[0]} />
              ) : (
                "Multiple destinations"
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          {entry.subject}
        </TableCell>
        <TableCell>
          {entry.createdBy ? (
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item>
                <CustomAvatar
                  text={entry.createdBy.fullName}
                  picture={entry.createdBy.picture}
                  size="small"
                />
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <Typography variant="body2" display="block" noWrap>
                  {entry.createdBy.fullName}
                </Typography>
                <Typography variant="caption" display="block" noWrap>
                  {moment
                    .utc(entry.createdAt)
                    .local()
                    .format("MMMM Do, YYYY  HH:mm")}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            "N/A"
          )}
        </TableCell>
        <TableCell>
          {entry.executionDate ? (
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="caption" display="block" noWrap>
                    {moment
                      .utc(entry.executionDate)
                      .local()
                      .format("MMMM Do, YYYY  HH:mm")}
                  </Typography>
                  <Typography variant="caption">
                    {Intl.DateTimeFormat().resolvedOptions().timeZone}
                  </Typography>
                </React.Fragment>
              }
            >
              <div>
                <Typography variant="body2" display="block" noWrap>
                  {moment
                    .utc(entry.executionDate)
                    .tz(entry.timezone)
                    .format("MMMM Do, YYYY  HH:mm")}
                </Typography>
                <Typography variant="caption">{entry.timezone}</Typography>
              </div>
            </Tooltip>
          ) : (
            "immediate"
          )}
        </TableCell>
        <TableCell>
          <ExecutedStatus value={entry.executedAt !== null} />
          {entry.executedAt === null && entry.executionDate !== null && (
            <React.Fragment>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="edit user"
                component="span"
                onClick={(event) => setOpenEdit(true)}
                title="Edit user"
              >
                <GeEditIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                size={"small"}
                color="secondary"
                aria-label="delete"
                component="span"
                onClick={handleClickDelete}
                title="Delete"
              >
                <GeDeleteIcon fontSize="inherit" />
              </IconButton>
            </React.Fragment>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Grid container alignItems={"center"}>
            <Grid item sm={12}>
              <Collapse in={show} mountOnEnter unmountOnExit>
                <Box className={classes.detailsContainer}>
                  <EntryDetails entry={entry} />
                </Box>
              </Collapse>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <Edit
        entry={entry}
        open={openEdit}
        setOpen={setOpenEdit}
        onEdit={refreshItems}
      />
      <ConfirmDialog
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Delete Ad Hoc Notification"}
        message={
          "Are you sure that you want to delete this ad hoc notification?"
        }
        notes={
          "The system will make internal checks to see the feasibility of the deletion request"
        }
        onCloseSuccess={acceptDelete}
        successParams={null}
        onClose={cancelDelete}
      />
    </React.Fragment>
  );
};

export default Entry;
