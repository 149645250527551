import CustomAvatar from "../../../General/CustomAvatar";
import EditIcon from "@mui/icons-material/Edit";
import { GeSettings } from "../../../General/GeneralIcons";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import moment from "moment-timezone";

const Entry = ({ entry, onEdit }) => {
  const serverTime = moment.tz(entry.time, "HH:mm:ss", entry.timezone);
  const localTime = serverTime.clone().local();
  const isBefore = localTime.isBefore(serverTime.format("YYYY-MM-DD"), "day");
  const isAfter = localTime.isAfter(serverTime.format("YYYY-MM-DD"), "day");

  const handleClickEdit = (event) => {
    onEdit(event, entry);
  };

  return (
    <TableRow>
      <TableCell>
        <Typography variant="body2">{entry.jobName}</Typography>
        <Typography variant="caption">{entry.description}</Typography>
      </TableCell>
      <TableCell>
        {entry.executeDateModeApplies ? entry.executeDayMode.name : "-"}
      </TableCell>
      <TableCell>
        <Typography variant="body2">{entry.day.name}</Typography>
        <Typography variant="body2">
          {serverTime.format("HH:mm")} {entry.timezone}
        </Typography>
        <Typography variant="caption" display="block">
          {localTime.format("HH:mm")} Local
          {isBefore ? " (day before)" : null}
          {isAfter ? " (day after)" : null}
        </Typography>
      </TableCell>
      <TableCell>
        {entry.createdAt ? (
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item>
              {entry.createdBy ? (
                <CustomAvatar
                  text={entry.createdBy.fullName}
                  picture={entry.createdBy.picture}
                />
              ) : (
                <GeSettings />
              )}
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Typography variant="body2" display="block" noWrap>
                {entry.createdBy ? entry.createdBy.fullName : "System"}
              </Typography>
              <Typography variant="caption" display="block" noWrap>
                {moment.utc(entry.createdAt).local().format("lll")}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          "N/A"
        )}
      </TableCell>
      <TableCell>
        {entry.updatedAt ? (
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item>
              {entry.updatedBy ? (
                <CustomAvatar
                  text={entry.updatedBy.fullName}
                  picture={entry.updatedBy.picture}
                />
              ) : (
                <GeSettings />
              )}
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Typography variant="body2" display="block" noWrap>
                {entry.updatedBy ? entry.updatedBy.fullName : "System"}
              </Typography>
              <Typography variant="caption" display="block" noWrap>
                {moment.utc(entry.updatedAt).local().format("lll")}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          "N/A"
        )}
      </TableCell>
      <TableCell>
        <IconButton
          size={"small"}
          color="secondary"
          aria-label="edit"
          component="span"
          onClick={handleClickEdit}
          title="Edit"
        >
          <EditIcon fontSize="inherit" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default Entry;
